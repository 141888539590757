const initialState = {
  tags: [],
  purchases: [],
  selectedTagId: null,
  selectedTag: {},
  pageSize: 5,
  pageNumber: 1,
  pageCount: 0,
  editTagDialogOpened: false,
  editTagDialogErrors: [],
  editTag: {},
  editTagId: null,
  isTagPurchasesLoading: false,
  openSelectTagDialog: false,
  selectedPurchase: {},
  newTagName: '',
  isTagsLoading: false
}

const reducer = (state = initialState, action) => {
  if (action.type === 'TAG_SELECTED') {
    return {
      ...state,
      purchases: [],
      isTagPurchasesLoading: true
    }
  }

  if (action.type === 'GET_TAGS_STARTED') {
    return {
      ...state,
      isTagsLoading: true
    }
  }

  if (action.type === 'GET_TAGS_ERROR') {
    return {
      ...state, 
      isTagsLoading: false
    }
  }

  if (action.type === 'GET_TAGS_FINISHED') {
    return {
      ...state,
      tags: action.tags,
      isTagsLoading: false
    }
  }

  if (action.type === 'PURCHASES_FOR_TAG_FOUND') {
    return {
      ...state,
      purchases: action.purchases,
      selectedTagId: action.selectedTagId,
      pageCount: action.pageCount,
      pageNumber: action.pageNumber,
      isTagPurchasesLoading: false
    }
  }

  if (action.type === 'ERROR_WHILE_GET_PURCHASES_FOR_TAG') {
    return {
      ...state,
      isTagPurchasesLoading: false
    }
  }

  if (action.type === 'PURCHASE_TAG_REMOVED') {
    let newPurchases = state.purchases.filter(item => item.id !== action.purchaseId);
    return {
      ...state,
      purchases: newPurchases
    }
  }

  if (action.type === 'TAG_REMOVED') {
    let newTags = state.tags.filter(item => item.id !== action.tagId)
    return {
      ...state,
      tags: newTags,
      selectedTagId: null,
      purchases: []
    }
  }

  if (action.type === 'EDIT_DIALOG_OPEN') {
    return {
      ...state,
      editTagDialogOpened: true,
      editTag: action.editTag,
      editTagId: action.editTagId,
      editTagDialogErrors: []
    }
  }

  if (action.type === 'EDIT_DIALOG_CANCEL') {
    return {
      ...state,
      editTagDialogOpened: false,
      editTag: {},
      editTagId: null
    }
  }

  if (action.type === 'ADD_DIALOG_SAVE') {
    var newTags = [...state.tags, action.tag];
    return {
      ...state,
      tags: newTags,
      editTagDialogOpened: false,
      editTagDialogErrors: [],
      editTag: {},
      editTagId: null
    }
  }

  if (action.type === 'EDIT_DIALOG_SAVE') {
    var newTags = state.tags.map((item, index) => {
      if (item.id == action.tagId)
      {
        item.name = action.tag.name;
      }
      return {
        ...item
      }
    });
    return {
      ...state,
      tags: newTags,
      editTagDialogOpened: false,
      editTagDialogErrors: [],
      editTag: {},
      editTagId: null
    }
  }

  if (action.type === 'EDIT_DIALOG_ERROR') {
    return {
      ...state,
      editTagDialogErrors: action.errors
    }
  }

  if (action.type === 'TAG_NAME_EDIT') {
    state.editTag.name = action.tagName;
    return {
      ...state
    }
  }

  if (action.type === 'UPDATE_TAG_LIST') {
    return {
      ...state,
      tags: action.tags
    } 
  }

  if (action.type === 'REFRESH_PURCHASE_TAGS') {
    const newPurchases = state.purchases.filter(item => item.id !== action.purchase.id);
    return {
      ...state,
      purchases: newPurchases
    }
  }

  return state;
}

export default reducer;