import React from "react"
import { Typography } from "@mui/material"
import { DateTime } from 'luxon';

export const PurchaseStageAndDates = (props) => {
    const purchase = props.purchase;

    const getFormatDate = (inputDate) => {
        const date = DateTime.fromISO(inputDate);
        const formattedDate = date.toFormat('dd.MM.yyyy HH:mm');
    
        return formattedDate;
    }  
    
    const getLocalFormatDate = (inputDate) => {
        const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const date = DateTime.fromISO(inputDate);
        const formattedDate = date.setZone(localTimezone).toFormat('dd.MM.yyyy HH:mm');
    
        return formattedDate;
    }
    
    const getDateDiff = (inputDate) => {
        const date = DateTime.fromISO(inputDate);
        
        const currentDate = DateTime.now();
        let result = "";
        let dateDiff = date.diff(currentDate, ['days', 'hours']).toObject();
    
        if (dateDiff.days === 1 || (dateDiff.days === 0 && dateDiff.hours === 1)) {
          result = result.concat("Остался ")
        }
        else {
          result = result.concat("Осталось ");
        }
    
        if (dateDiff.days > 0) {
          result = result.concat(dateDiff.days+" д. ");
        }
        if (dateDiff.hours > 1) {
          result = result.concat(parseInt(dateDiff.hours)+" ч. ");
        }
        if (dateDiff.days === 0 && dateDiff.hours < 1 && dateDiff.hours > 0) {
          result = result.concat("менее часа");
        }
        if (dateDiff.hours < 0){
          result = "";
        }
    
        return result;
    }

    switch(purchase.stage) {
        case 1:
            if (!purchase.purchaseExpirationAt) {
            return <Typography>Подача заявок</Typography>;
            }

            const dateMsk = getFormatDate(purchase.purchaseExpirationAt);
            const dateLocal = getLocalFormatDate(purchase.purchaseExpirationAt);

            return (
            <React.Fragment>
                <Typography sx={{fontFamily: "SF Pro Text, sans-serif", fontSize: "14px", letterSpacing: "-0.41px"}}>Подача заявок до: <b>{dateMsk}</b> (мск)</Typography>
                {/*<Typography>{dateMsk} (мск)</Typography>*/}
                {dateMsk != dateLocal ? <Typography>{dateLocal} (местное)</Typography> : null}
                <Typography>{getDateDiff(purchase.purchaseExpirationAt)}</Typography>
            </React.Fragment>
            );
        case 2:
        return 'Работа комиссии';
        case 3:
        return 'Размещение завершено';
        case 4:
        return 'Размещение отменено';
        case 5:
        return 'Торги не состоялись';
        case 6: 
        return 'Торги запланированы';
        case 7: 
        return 'На исполнении';
        default:
        return 'Не определено';
    }
}
