import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from '../../withRouter';
import OrganizationInfo from './OrganizationInfo';
import OrganizationPurchasesAsCustomer from './OrganizationPurchasesAsCustomer';
import OrganizationPurchasesAsSupplier from './OrganizationPurchasesAsSupplier';
import CustomerReviewsPanel from '../CustomerReviewsPanel';
import CustomerRatingService from '../../services/CustomerRatingService';
import styles from '../../css/Organizations/OrganizationResult.module.css'
// MUI
import {Box, Grid, Rating, Tab, Typography} from '@mui/material';

import {TabContext, TabList, TabPanel} from '@mui/lab';
import {ThemeProvider} from "@mui/material/styles";

class OrganizationResult extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: '1',
            averageRating: 0
        }
    }

    componentDidMount() {
        if (this.props.organization) {
            CustomerRatingService.getCustomerRating(this.props.organization.id)
                .then(rating => {
                    this.setState({averageRating: parseFloat(rating)});
                });
        }
    }

    render() {
        if (this.props.organization) {
            return (
                <React.Fragment>
                    <TabContext value={this.state.activeTab}>
                        <Box className={styles.container} sx={{minWidth: 275, mt: 1}}>
                            <Box>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography variant='h6' className={styles.organizationName}>
                                            {this.props.organization.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{paddingTop: "10px!important"}}>
                                        <Rating value={this.state.averageRating} precision={0.1} readOnly/>
                                    </Grid>

                                    <Box className={styles.organizationInfoContainer}>
                                        <Typography className={styles.subtitle}>
                                            Общая информация
                                        </Typography>
                                        <OrganizationInfo/>
                                    </Box>
                                </Grid>
                            </Box>
                            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                <ThemeProvider
                                    theme={{
                                        components: {
                                            MuiTab: {
                                                styleOverrides: {
                                                    root: {
                                                        fontSize: "15px",
                                                        textTransform: "unset",
                                                        fontFamily: "SF Pro Text, sans-serif",
                                                        letterSpacing: "-0.41px",
                                                        fontWeight: "400",
                                                        padding: "16px 12px",
                                                        '&.Mui-selected': {
                                                            color: "#2B65F0"
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }}
                                >
                                    <TabList
                                        classes={{
                                            indicator: styles.indicator
                                        }}
                                        className={styles.tablistContainer}
                                        onChange={(event, newValue) => {
                                            this.setState({activeTab: newValue})
                                        }}>
                                        {/*<Tab label="Общая информация" value='0' />*/}
                                        <Tab label="Закупки в качестве заказчика" value='1'/>
                                        <Tab label="Закупки в качестве поставщика" value='2'/>
                                        <Tab label="Отзывы" value='3'/>
                                    </TabList>
                                </ThemeProvider>
                            </Box>
                        </Box>

                        <Box>
                            <TabPanel value='1' className={styles.tabPanelContainer}><OrganizationPurchasesAsCustomer/></TabPanel>
                            <TabPanel value='2' className={styles.tabPanelContainer}><OrganizationPurchasesAsSupplier/></TabPanel>
                            <TabPanel value='3' className={styles.tabPanelContainer}><CustomerReviewsPanel
                                customerOrganizationId={this.props.organization.id}/></TabPanel>
                        </Box>
                    </TabContext>
                </React.Fragment>);
        } else {
            return (
                <Box className={styles.notFoundContainer}>
                    Компания не найдена
                </Box>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        organization: state.organizationsState.organization
    }
}

export default connect(mapStateToProps)(withRouter(OrganizationResult));
