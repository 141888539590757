class DocumentsService {
    async get(path, filename) {
      var baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558';   
      const user = JSON.parse(localStorage.getItem(`oidc.user:${baseIdentityURL}:web_client`));

      const requestOptions = {
        method: 'GET',
        headers: { 
          'Content-Type': 'application/json', 
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + (user ? user.access_token : ''),
        }
      };
  
      const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_API_URI : 'http://localhost:5552';
      const response = await fetch(`${baseURL}/api/purchases/documents?path=${path}&fileName=${filename}`, requestOptions);

      if (response.status !== 200) {
        throw "Произошла ошибка при скачивании файла";
      }

      var blob = await response.blob();
      return blob;
    }
  }
  
  export default new DocumentsService();