import React from 'react';
import { withRouter } from '../../withRouter';
import CustomerRatingService from '../../services/CustomerRatingService';
import CustomerReviewMessage from './CustomerReviewMessage';

//MUI
import { Button, TextField, Grid, Rating, Card, CardContent, Typography, Box, CircularProgress } from '@mui/material';
import styles from "../../css/Organizations/Organizations.module.css";

class CustomerReview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reviewRequestId: '',
      reviewText: '',
      rating: 0,
      author: '',
      reviewRequest: null,
      isSuccess: false,
      isCompleted: false,
      isError: false
    };
    this.sendReview = this.sendReview.bind(this);
  }

  componentDidMount() {
    const params = this.props.params;
    
    this.setState({
      reviewRequestId: params.reviewRequestId
    })

    CustomerRatingService.GetReviewRequest(params.reviewRequestId)
      .then(result => {
        if (result) {
          this.setState({
            reviewRequest: result
          })
        }
        else {
          this.setState({isCompleted: true});
        }
      });
  }

  sendReview() {
    CustomerRatingService.SendReview({
      organizationId: this.state.reviewRequest.customerOrgId,
      reviewRequestId: this.state.reviewRequestId,
      PurchaseId: this.state.reviewRequest.purchaseId,
      Author: this.state.author,
      Rating: this.state.rating,
      Text: this.state.reviewText
    }).then(result => {
      if (result) {
        this.setState({isSuccess: true});
      }
      else {
        this.setState({isError: true});
      }
    });
  }
  
  render() {
    if (this.state.isSuccess)
    {
      return (
        <CustomerReviewMessage text="Отзыв успешно принят! Спасибо за высказанное мнение."/>
      );
    }
    if (this.state.isCompleted)
    {
      return (
        <CustomerReviewMessage text="К сожалению по вашей ссылке отзыв уже принят."/>
      );
    }
    if (this.state.isError)
    {
      return (
        <CustomerReviewMessage text="При обработке запроса произошла ошибка."/>
      );
    }
    if (this.state.reviewRequest) { 
      return (
        <React.Fragment>
        <Card variant="outlined" sx={{ minWidth: 275, mt: 1 }}>
          <CardContent>
            <Grid container spacing={3}>

              <Grid item xs={12}>
                <Typography variant="h5" sx={{ pt: 3 }} color="text.secondary" component="div" align='center'>
                  ОТЗЫВ НА ЗАКАЗЧИКА ЗАКУПКИ
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    Организация-заказчик:
                  </Grid>
                  <Grid item xs={9}>
                    <Typography component="div">{this.state.reviewRequest.customerOrgName}</Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    Закупка:
                  </Grid>
                  <Grid item xs={9}>
                    <Typography component="div">{this.state.reviewRequest.purchaseTitle}</Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    Организация-поставщик:
                  </Grid>
                  <Grid item xs={9}>
                    <Typography component="div">{this.state.reviewRequest.supplierOrgName}</Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center'}}>
                    Автор отзыва
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      id="author"
                      variant="outlined"
                      size="small"
                      onChange={(event) => {
                        var author = event.target.value;
                        this.setState({
                          author: author
                        });
                      }}
                      value={this.state.author}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                    Отзыв:
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      id="reviewText"
                      variant="outlined"
                      multiline
                      minRows="5"
                      fullWidth
                      onChange={(event) => {
                        var reviewText = event.target.value;
                        this.setState({
                          reviewText: reviewText
                        });
                      }}
                      value={this.state.reviewText}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                    Оценка:
                  </Grid>
                  <Grid item xs={9}>
                      <Rating 
                        value={this.state.rating}
                        onChange={(event, newValue) => {
                          this.setState({
                            rating: newValue
                        })
                      }}
                      />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Button
                  onClick={this.sendReview}
                  type="submit"
                  variant="contained"
                >Отправить</Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </React.Fragment>
      )
    }
    else {
      return (
        <Box className={styles.loadingContainer}>
          <CircularProgress />
        </Box>
      );      
    }
  }
}

//export default connect(mapStateToProps)(withRouter(CustomerReview));
export default withRouter(CustomerReview);
