import React, { Component } from 'react';
import Box from '@mui/material/Box';
import PurchaseTransportLogPanel from './PurchaseTransportLogPanel';
import PurchaseTransportLogResult from './PurchaseTransportLogResult';
import { connect } from 'react-redux';

class PurchaseTransportLogs extends Component {
  componentDidMount() {
    this.props.onPurchaseTransportLogsPage();
  }

  render() {
    return (
      <React.Fragment>
        <Box>
          <PurchaseTransportLogPanel />
        </Box>
        <Box sx={{ mt: 2 }}>
          <PurchaseTransportLogResult />
        </Box>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {

  }
}

function mapDispatchToProps(dispatch) {
  return {
		onPurchaseTransportLogsPage: () => {
      const action = {
        type: 'PURCHASE_LOG_PAGE'
      };
      dispatch(action);
		}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseTransportLogs);