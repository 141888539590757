import React, { useState } from "react"
import { Typography, Box, Button, Container, TextField, FormGroup, FormHelperText } from '@mui/material'
import { withRouter } from '../../withRouter';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import * as Yup from "yup";

const PasswordResetByEmail = (props) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [errors, setErrors] = useState([]);

    const handlePasswordReset = async (formValues) => {
        setErrors([]);

        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*', 
            },
            credentials: 'include',
            body: JSON.stringify({
                Email: searchParams.get('email'),
                Password: formValues.password,
                Token: searchParams.get('token')
            })
        };

        const baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558';	
        await fetch(baseIdentityURL + "/api/account/reset-password-by-email", requestOptions)
            .then(res => res.json())
            .then((result) => {
                if (result.isOk == true) {
                    navigate('/password-reset-success');
                }
                else {
                    setErrors(result.errors);
                }
            })
            .catch((result) => {
                if (result.Message) {
                    setErrors([result.Message]);
                }
        });
    }

    const validationSchema = Yup.object({
        password: Yup.string()
            .min(6, "Пароль должен быть не менее 6 символов")
            .required('Обязательное поле'),
        confirm_password: Yup.string()
            .min(6, "Пароль должен быть не менее 6 символов")
            .oneOf([Yup.ref('password')], 'Введенные пароли не совпадают')
            .required('Обязательное поле'),
    });

    return (
        <React.Fragment>
            <Container maxWidth="xs">
				<Box
					sx={{
						marginTop: 8,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
                    <Typography component="h1" variant="h5">
                        Восстановление пароля
                    </Typography>
                    <Formik 
                        initialValues={{ password: '', confirm_password: ''}}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            handlePasswordReset(values);
                            setSubmitting(false);
                        }}
                    >
                    { formik => (
                        <Form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
                            <Field name="password">
                                {({field, form, meta}) => (
                                    <TextField
                                        margin="normal"
                                        fullWidth
                                        name="password"
                                        label="Новый пароль"
                                        type="password"
                                        id="password"
                                        autoComplete="current-password"
                                        helperText={meta.touched && meta.error}
                                        error={ meta.touched && meta.error != null }
                                        {...formik.getFieldProps('password')}
                                    />
                                )}
                            </Field>
                            <Field name="confirm_password" >
                                {({field, form, meta}) => (
                                    <FormGroup>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            type="password"
                                            id="confirm_password"
                                            label="Подтверждение нового пароля"
                                            name="confirm_password"
                                            {...formik.getFieldProps('confirm_password')}
                                            error={meta.touched  && meta.error != null}
                                            helperText={meta.touched && meta.error}
                                        />
                                    </FormGroup>
                                )}
                            </Field>

                            {/* server errors */}
                            { errors.map((error, key) => <FormHelperText error key={key}>{error}</FormHelperText>) }

                            <Button
                                type="submit"
                                disabled={formik.isSubmitting}
                                fullWidth
                                variant="contained"
                                sx={{ mt: 1, mb: 2 }}
                            >
                                Сменить пароль
                            </Button>
                        </Form>
                    )}
                    </Formik>
			    </Box>
            </Container>
		</React.Fragment>
    )
}

export default withRouter(PasswordResetByEmail);
