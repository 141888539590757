import { useEffect } from 'react'
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

let displayed = [];

export const Notifier = (props) => {
  const { enqueueSnackbar } = useSnackbar();

  const reduxState = useSelector((state) => { return {
    notifications: state.notificationsState.notifications
    };
  });
  const dispatch = useDispatch();

  const onRemoveNotification = (key) => {
        const notificationAction = {
            type: 'SNACKBAR_NOTIFICATION_REMOVED',
            key: key
        }
        dispatch(notificationAction);
    }

  const storeDisplayed = (id) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id) => {
      displayed = [...displayed.filter(key => id !== key)];
  };

  useEffect(() => {
    reduxState.notifications.forEach(({key, message, variant}) => {
      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) return;

      enqueueSnackbar(
        message, 
        { 
          key: key,
          variant: variant,
          onExited: (event, key) => {
            onRemoveNotification(key);
            removeDisplayed(key);
          }
        });
      
      // keep track of snackbars that we've displayed
      storeDisplayed(key);
    })
  }, [reduxState.notifications])
}