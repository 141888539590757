import React, { Component } from 'react';
import { MenuItem, Select } from '@mui/material';
import { connect } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import PurchaseTransportLogService from '../services/PurchaseTransportLogService';

class PurchaseTransportLogResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
			page: 0
    };

		this.handleChangePage = this.handleChangePage .bind(this);
  }

	handleChangePage(event, newPage) {
		this.props.onGetUnpublishedPurchases(newPage);
		this.setState({ page: newPage });
	}

  render() {
		if (this.props.purchaseTransportLogs.length === 0) {
			return ("Данных нет");
		} else {
			return (
				<React.Fragment>
					<TableContainer component={Paper}>
						<Table sx={{ minWidth: 650 }} aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell>Номер закупки</TableCell>
									<TableCell>Площадка</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{this.props.purchaseTransportLogs?.map((purchaseTransportLog) => (
									<TableRow
										key={purchaseTransportLog.id}
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										<TableCell component="th" scope="row">
											{purchaseTransportLog.purchaseNumber}
										</TableCell>
										<TableCell>{purchaseTransportLog.sourcePlatform.name}</TableCell>
									</TableRow>
								))}
							</TableBody>
							<TableFooter>
								<TableRow>
									<TablePagination
										rowsPerPageOptions={[20]}
										// colSpan={3}
										count={this.props.total}
										rowsPerPage={20} // количество отображаемых элементов
										page={this.state.page}
										// SelectProps={{
										// 	inputProps: {
										// 		'aria-label': 'rows per page',
										// 	},
										// 	native: true,
										// }}
										onPageChange={this.handleChangePage}
										// onRowsPerPageChange={handleChangeRowsPerPage}
										// ActionsComponent={TablePaginationActions}
									/>
								</TableRow>
							</TableFooter>						
						</Table>
					</TableContainer>

				</React.Fragment>
			);
		}
  }
}

function mapStateToProps(state) {
  if (state.pagesState.currentPage === "PURCHASE_LOGS") {
    return {
      purchaseTransportLogs: state.purchaseTransportLogsState.purchaseTransportLogs,
		  total: state.purchaseTransportLogsState.total
    };
  }

  return {
    purchaseTransportLogs: [],
  }	
}

function mapDispatchToProps(dispatch) {
  return {
		onGetUnpublishedPurchases: (pageNumber) => {
      const action = {
        type: 'UNPUBLISHED_PURCHASES'
      };

			PurchaseTransportLogService.getUnpublishedPurchases(pageNumber)
				.then(result => {
					action.purchaseTransportLogs = result.collections;
					action.pageNumber = result.pageNumber;
					action.pageSize = result.pageSize;
					action.total = result.total;
					action.pageCount = result.pageCount;
					dispatch(action);
				});
		},
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseTransportLogResult);