class OkpdService {
  async getByParent(parentAndSelectedCodes) {
    const baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558';
    const user = JSON.parse(localStorage.getItem(`oidc.user:${baseIdentityURL}:web_client`));

    const requestOptions = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + (user ? user.access_token : ''),
      },
      body: JSON.stringify(parentAndSelectedCodes)
    };

    const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_OKPD_API_URI : 'http://localhost:5567';
    const response = await fetch(`${baseURL}/api/okpd/getByParent`, requestOptions);
    const okpdCodes = await response.json();

    return okpdCodes;
  }

  async search(searchText) {
    var baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558';   
    const user = JSON.parse(localStorage.getItem(`oidc.user:${baseIdentityURL}:web_client`));

    const requestOptions = {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + (user ? user.access_token : ''),
      }
    };

    //если поиск идет по строке не содержащей ни одной буквы или цифры - выдаем пустой результат
    const regex = new RegExp("[a-zA-Zа-яА-Я0-9]+");
    if (!regex.test(searchText)) {
        return { count: 0, okpdCodes: []};
    }

    const uriEncodedSearchText = encodeURIComponent(searchText);
    const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_OKPD_API_URI : 'http://localhost:5567';
    const response = await fetch(`${baseURL}/api/okpd/search/${uriEncodedSearchText}`, requestOptions);
    const searchResult = await response.json();

    return searchResult;
  }
}

export default new OkpdService();