class IndustriesService {
  async getIndustries() {
    var baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558';   
    const user = JSON.parse(localStorage.getItem(`oidc.user:${baseIdentityURL}:web_client`));

    const requestOptions = {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + (user ? user.access_token : ''),
      }
    };

    const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_INDUSTRIES_API_URI : 'http://localhost:5568';
    const response = await fetch(`${baseURL}/api/industry/tree`, requestOptions);
    const industries = await response.json();

    return industries;
  }
}

export default new IndustriesService();