import React from 'react'
import styles from '../css/Header/Header.module.css'
import Menu from "./Menu";
import AuthHeader from "./Authentication/AuthHeader";
import {isMobile} from 'react-device-detect';

const Header = () => {

    return (
        <div className={styles.container}>
            <div className={styles.containerInner}>
                <img src="/logo_new.svg" alt="logo" className={styles.logo}/>
                <Menu/>
                {!isMobile && <AuthHeader/>}
            </div>
        </div>
    )
}


export default Header
