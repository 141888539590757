import React, {Component} from 'react';
import {withRouter} from '../withRouter';
import PurchaseService from '../services/PurchaseService';
import PurchaseTagsService from '../services/PurchaseTagsService';
import {DateTime} from 'luxon';
import CustomerDetails from "../components/CustomerDetails";
import CustomerReviewsPanel from "../components/CustomerReviewsPanel";
import PurchaseLotDetails from "./PurchaseLotDetails";
import SelectTagDialog from './SelectTagDialog';
import {connect} from 'react-redux';
import {DownloadDocumentLink} from './download-document-link'
import styles from '../css/PurchaseDetails/PurchaseDetails.module.css'

// MUI:
import {Box, Button, Chip, CircularProgress, Grid, Link, Stack} from '@mui/material';
import {isMobile} from "react-device-detect";

class PurchaseDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            purchase: null,
            isLoading: false
        };
    }

    componentDidUpdate(prevProps) {
        //rerender when purchase tags changed
        if (this.props.tagDialogSelectedPurchase?.id && this.props.tagDialogSelectedPurchase.id === this.state.purchase?.id && this.props.tagDialogSelectedPurchase.tags !== this.state.purchase.tags) {
            this.setState({purchase: {...this.state.purchase, tags: this.props.tagDialogSelectedPurchase.tags}})
        }
    }

    componentDidMount() {
        const purchaseId = this.props.params.purchaseId;
        this.setState({isLoading: true});

        PurchaseService.get(purchaseId)
            .then(purchase => {
                PurchaseTagsService.getPurchaseTags([purchaseId])
                    .then((result) => {
                        result.forEach((resultPurchase) => {
                            if (resultPurchase.id === purchaseId) {
                                purchase.tags = resultPurchase.tags;
                            }
                        })
                        this.setState({purchase: {...purchase}, isLoading: false});
                    })
                    .catch(() => {

                    })
            })
            .catch(error => {
                this.props.onSendError(error);
                this.setState({isLoading: false});
            });
    }

    getSource = () => {
        const block = (
            this.state.purchase.sourceUrls
                .filter((url) => {
                    if (url.type === 0 || url.type === 1) {
                        return true;
                    }
                    return false;
                })
                .map((url, key) => {
                    if (url.type === 1) return (
                        <div key={key}>
                            <Link rel="noopener noreferrer" target="_blank" href={url.url} underline="hover"
                                  className={styles.sourceLink}>
                                Ссылка на ЕИС
                            </Link>
                        </div>
                    )
                    if (url.type === 0) return (
                        <div key={key}>
                            <Link rel="noopener noreferrer" target="_blank" href={url.url} underline="hover"
                                  className={styles.sourceLink}>
                                Ссылка
                            </Link>
                        </div>
                    )
                })
        );

        if (block && block.length > 0) {
            return block;
        }

        if (this.state.purchase.sourceUrl) {
            return <Link rel="noopener noreferrer" target="_blank" href={this.state.purchase.sourceUrl}
                         underline="hover" className={styles.sourceLink}>
                {/* {this.state.purchase.source.name} */}
                Источник
            </Link>
        } else {
            return null
        }
    }

    getDocuments = () => {
        if (this.state.purchase.documents.length > 0) {
            return (
                <ul className={styles.linksList}>
                    {
                        this.state.purchase.documents.map((document, key) =>
                            <li key={key}>
                                <DownloadDocumentLink path={document.pathToFileInStorage} filename={document.fileName}>
                                    <img src="/document_icon.svg" alt="Document"/>
                                    {document.fileName}
                                </DownloadDocumentLink>
                            </li>
                        )
                    }
                </ul>
            )
        } else {
            return (
                <div>Документы отсутствуют</div>
            )
        }
    }

    getFormatDate = (inputDate) => {
        const date = DateTime.fromISO(inputDate);
        return date.toFormat('dd.MM.yyyy HH:mm');
    }

    getLaw = (law) => {
        switch (law) {
            case 1:
                return '44-ФЗ';
            case 2:
                return '223-ФЗ';
            case 3:
                return 'Коммерческие';
            case 4:
                return 'ПП РФ 615';
            default:
                return 'Не определено';
        }
    }

    getCurrency = (currencyId) => {
        switch (currencyId) {
            case 1:
                return '₽';
            default:
                return '';
        }
    }

    render() {
        if (this.state.purchase) {
            return (
                <div className={styles.container}>
                    <SelectTagDialog/>

                    <div className={styles.containerInner}>
                        <div className={styles.titleActionsWrapper}>
                            <span className={styles.purchaseTitle}>{this.state.purchase.title}</span>
                            <div className={styles.titleActionsContainer}>
                                <span className={styles.purchaseNumber}>
                                  № {this.state.purchase.number} ({this.getLaw(this.state.purchase.law)})
                                </span>
                                <Button
                                    sx={{
                                        borderRadius: '8px',
                                        fontFamily: 'SF Pro Text, serif',
                                        fontSize: "16px",
                                        textTransform: "unset!important",
                                        letterSpacing: "-0.41px",
                                        color: "#2B65F0",
                                        fontWeight: "500",
                                        minWidth: isMobile ? 'unset!important' : '64px',
                                        padding: isMobile ? '9px' : '4px 10px',
                                        border: isMobile ? '1px solid #B0B0B6' : '',
                                        '& .MuiButton-startIcon': {
                                            marginRight: isMobile ? "0!important" : "8px",
                                            marginLeft: isMobile ? "0!important" : "-4px"
                                        }
                                    }}
                                    startIcon={<img src="/star_icon.svg" alt="Close"/>}
                                    variant={isMobile ? 'outlined' : "text"}
                                    onClick={() => this.props.onPurchaseTagAddCalled(this.state.purchase, this.props.enqueueSnackbar)}
                                >
                                    {isMobile ? '' : 'Избранное'}
                                </Button>
                            </div>
                            <Stack direction="row" justifyContent='space-between' spacing={1} className={styles.tagsStack}>
                                <Stack direction="row" justifyContent='space-between' spacing={1}>
                                    {!!this.state.purchase.tags &&
                                        this.state.purchase.tags.map((tag, key) =>
                                            <Chip label={tag.name} key={key}/>
                                        )
                                    }
                                </Stack>
                            </Stack>
                        </div>

                        <div className={styles.infoContainer}>
                            {this.state.purchase.sourcePlatform && this.state.purchase.sourcePlatform.id ?
                                <Grid container spacing={3} className={styles.gridRow}>
                                    <Grid item xs={4} className={styles.infoTitle} sx={{p: 0}}>
                                        Площадка
                                    </Grid>
                                    <Grid item xs={8} sx={{p: 0}}>
                                        {this.state.purchase.sourcePlatform.name}
                                    </Grid>
                                </Grid> : null
                            }

                            {this.state.purchase.sourcePlatform && this.state.purchase.sourcePlatform.id ?
                                <Grid container spacing={3} className={styles.gridRow}>
                                    <Grid item xs={4} className={styles.infoTitle} sx={{p: 0}}>
                                        Тип торгов
                                    </Grid>
                                    <Grid item xs={8}>
                                        {this.state.purchase.methodType}
                                    </Grid>
                                </Grid> : null
                            }

                            {!!this.state.purchase.sourceDeliveryAddress &&
                                <Grid container spacing={3} className={styles.gridRow}>
                                    <Grid item xs={4} className={styles.infoTitle} sx={{p: 0}}>
                                        Адрес доставки
                                    </Grid>
                                    <Grid item xs={8}>
                                        {this.state.purchase.sourceDeliveryAddress}
                                    </Grid>
                                </Grid>
                            }

                            {this.state.purchase.prepayment > 0 &&
                                <Grid container spacing={3} className={styles.gridRow}>
                                    <Grid item xs={4} className={styles.infoTitle} sx={{p: 0}}>
                                        Предоплата
                                    </Grid>
                                    <Grid item xs={8}>
                                        {this.state.purchase.prepayment} %
                                    </Grid>
                                </Grid>
                            }

                            {!!this.state.purchase.price &&
                                <Grid container spacing={3} className={styles.gridRow}>
                                    <Grid item xs={4} className={styles.infoTitle} sx={{p: 0}}>
                                        Цена
                                    </Grid>
                                    <Grid item xs={8}>
                                        {this.state.purchase.price} {this.getCurrency(this.state.purchase.currency)}
                                    </Grid>
                                </Grid>
                            }

                            {!!this.state.purchase.provisionRequest &&
                                <Grid container spacing={3} className={styles.gridRow}>
                                    <Grid item xs={4} className={styles.infoTitle} sx={{p: 0}}>
                                        Обеспечение заявки
                                    </Grid>
                                    <Grid item xs={8}>
                                        {this.state.purchase.provisionRequest}
                                    </Grid>
                                </Grid>
                            }

                            {!!this.state.purchase.provisionContract
                                &&
                                <Grid container spacing={3} className={styles.gridRow}>
                                    <Grid item xs={4} className={styles.infoTitle} sx={{p: 0}}>
                                        Обеспечение контракта
                                    </Grid>
                                    <Grid item xs={8}>
                                        {this.state.purchase.provisionContract}
                                    </Grid>
                                </Grid>
                            }

                            {!!this.state.purchase.provisionWarranty
                                &&
                                <Grid container spacing={3} className={styles.gridRow}>
                                    <Grid item xs={4} className={styles.infoTitle} sx={{p: 0}}>
                                        Обеспечение гарантийных обязательств
                                    </Grid>
                                    <Grid item xs={8}>
                                        {this.state.purchase.provisionWarranty}
                                    </Grid>
                                </Grid>
                            }

                            <Grid container spacing={3} className={styles.gridRow}>
                                <Grid item xs={4} className={styles.infoTitle} sx={{p: 0}}>
                                    Опубликован
                                </Grid>
                                <Grid item xs={8}>
                                    {this.getFormatDate(this.state.purchase.purchaseCreateAt)} <span
                                    style={{color: "#8A8A8E"}}>(мск)</span>
                                </Grid>
                            </Grid>

                            {!!this.state.purchase.purchaseExpirationAt &&
                                <Grid container spacing={3} className={styles.gridRow}>
                                    <Grid item xs={4} className={styles.infoTitle} sx={{p: 0}}>
                                        Окончание подачи
                                    </Grid>
                                    <Grid item xs={8}>
                                        {this.getFormatDate(this.state.purchase.purchaseExpirationAt)} <span
                                        style={{color: "#8A8A8E"}}>(мск)</span>
                                    </Grid>
                                </Grid>
                            }

                            {!!this.state.purchase.biddingDate &&
                                <Grid container spacing={3} className={styles.gridRow}>
                                    <Grid item xs={4} className={styles.infoTitle} sx={{p: 0}}>
                                        Дата торгов
                                    </Grid>
                                    <Grid item xs={8}>
                                        {this.getFormatDate(this.state.purchase.biddingDate)} (мск)
                                    </Grid>
                                </Grid>
                            }

                            <Grid container spacing={3} className={styles.gridRow}>
                                <Grid item xs={4} className={styles.infoTitle} sx={{p: 0}}>
                                    Источник
                                </Grid>
                                <Grid item xs={8}>
                                    {this.getSource()}
                                </Grid>
                            </Grid>
                        </div>
                    </div>

                    <div className={styles.gridContainer}>
                        <CustomerDetails customerOrganizationId={this.state.purchase.customerOrganizationId}/>

                        <Box className={styles.containerDocs}>
                            <span className={styles.purchaseTitle}>Документы</span>
                            <Box>
                                {this.getDocuments()}
                            </Box>
                        </Box>
                    </div>

                    <PurchaseLotDetails lots={this.state.purchase.lots}/>

                    <CustomerReviewsPanel customerOrganizationId={this.state.purchase.customerOrganizationId}/>
                </div>
            )
        } else if (this.state.isLoading) {
            return (
                <Box sx={{display: 'flex'}}>
                    <CircularProgress/>
                </Box>
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        tagDialogSelectedPurchase: state.selectTagDialogState.selectedPurchase
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onPurchaseTagAddCalled: (purchase) => {
            dispatch({type: 'OPEN_DIALOG'});
            PurchaseTagsService.getTags().then(result => {
                const action = {
                    type: 'OPEN_TAG_DIALOG',
                    selectedPurchase: purchase,
                    tags: result
                };
                dispatch(action);
            })
                .catch(() => {
                    //todo
                });
        },
        onSendError: () => {
            const notificationAction = {
                type: 'SNACKBAR_NOTIFICATION_SENT',
                notification: {
                    message: 'Не удалось получить информацию по закупке',
                    variant: 'error'
                }
            };
            dispatch(notificationAction);
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PurchaseDetails));
