import React, { Component } from 'react';
import { MenuItem, Select } from '@mui/material';
import { connect } from 'react-redux';
import PurchaseTransportLogService from '../services/PurchaseTransportLogService';

class PurchaseTransportLogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logType: 0
    };

		this.handleChange = this.handleChange.bind(this);
  }

	handleChange(event) {
		switch(event.target.value) {
			case 0:
				this.props.onEmptyUnpublishedPurchases();
				break;
			case 1:
				this.props.onGetUnpublishedPurchases();
				break;
		}
		
		this.setState({logType: event.target.value});
	}

  render() {
		return (
			<Select
				value={this.state.logType}
				onChange={this.handleChange}
			>
				<MenuItem value={0}>Не выбрано</MenuItem>
				<MenuItem value={1}>Неопубликованные</MenuItem>
			</Select>
		);
  }
}

function mapStateToProps(state) {
  return {
  }
}

function mapDispatchToProps(dispatch) {
  return {
		onGetUnpublishedPurchases: () => {
      const action = {
        type: 'UNPUBLISHED_PURCHASES'
      };

			PurchaseTransportLogService.getUnpublishedPurchases()
				.then(result => {
					action.purchaseTransportLogs = result.collections;
					action.pageNumber = result.pageNumber;
					action.pageSize = result.pageSize;
					action.total = result.total;
					action.pageCount = result.pageCount;
					dispatch(action);
				});
		},
		onEmptyUnpublishedPurchases: () => {
      const action = {
        type: 'EMPTY_UNPUBLISHED_PURCHASES'
      };

			dispatch(action);
		}
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseTransportLogs);