import React, {useState} from 'react';
import {
    Box,
    Button,
    Card,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle,
    Grid,
    IconButton,
    SwipeableDrawer,
    Tab,
    TextField,
    Typography
} from '@mui/material';
import styles from '../css/CustomerSelection/CustomerSelection.module.css'
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {useDispatch, useSelector} from "react-redux";
import CustomerService from '../services/CustomerService';
import InfiniteScroll from 'react-infinite-scroll-component';
import DialogContent from "@mui/material/DialogContent";
import {styled, ThemeProvider} from "@mui/material/styles";
import BpCheckbox from "./CustomCheckbox";
import {isMobile} from "react-device-detect";
import {grey} from "@mui/material/colors";

const size = 50;
const infiniteScrollElementDomName = "scrollableDom";

export const SearchResultsTabId = "0";
export const CheckedTabId = "1";

export function OrganizationCard(props) {
    return (
        <React.Fragment>
            <ThemeProvider theme={{
                components: {
                    MuiCard: {
                        styleOverrides: {
                            root: {
                                fontFamily: "SF Pro Text, serif",
                                letterSpacing: "-0.41px",
                            },
                        }
                    }
                }
            }}>
                <Card sx={{boxShadow: "none", background: "transparent"}}>
                    <Typography
                        sx={{fontFamily: "SF Pro Text, sans-serif", letterSpacing: "-0.41px", fontWeight: "500"}}>
                        {props.organization.name}
                    </Typography>

                    {props.organization.sourcePostalAddress
                        ?
                        <Grid>
                            <Typography variant='caption' sx={{
                                fontFamily: "SF Pro Text, sans-serif",
                                letterSpacing: "-0.41px",
                                fontSize: "13px",
                                color: "#8A8A8E"
                            }}>
                                {props.organization.sourceActualAddress}
                            </Typography>
                        </Grid>
                        :
                        null
                    }

                    <Grid sx={{lineHeight: "unset"}}>
                        <Typography variant='caption' sx={{
                            fontFamily: "SF Pro Text, sans-serif",
                            letterSpacing: "-0.41px",
                            fontSize: "13px",
                            color: "#8A8A8E"
                        }}>
                            {props.organization.inn
                                ?
                                `ИНН: ${props.organization.inn} `
                                :
                                null
                            }
                        </Typography>
                        <Typography variant='caption' sx={{
                            fontFamily: "SF Pro Text, sans-serif",
                            letterSpacing: "-0.41px",
                            fontSize: "13px",
                            color: "#8A8A8E"
                        }}>
                            {props.organization.kpp
                                ?
                                `КПП: ${props.organization.kpp} `
                                :
                                null
                            }
                        </Typography>
                        <Typography variant='caption' sx={{
                            fontFamily: "SF Pro Text, sans-serif",
                            letterSpacing: "-0.41px",
                            fontSize: "13px",
                            color: "#8A8A8E"
                        }}>
                            {props.organization.ogrn
                                ?
                                `ОГРН: ${props.organization.ogrn} `
                                :
                                null
                            }
                        </Typography>
                    </Grid>
                </Card>
            </ThemeProvider>
        </React.Fragment>
    )
}

export function CustomerSelection(props) {
    const [searchText, setSearchText] = useState('');


    const dispatch = useDispatch();
    const reduxState = useSelector((state) => {
        return {
            openModal: state.customersState.openModal,
            foundOrganizations: state.customersState.foundOrganizations,
            checkedOrganizations: state.customersState.checkedOrganizations,
            activeTabId: state.customersState.activeTabId,
            isLoading: state.customersState.isLoading,
            foundOrganizationsTotalCount: state.customersState.foundOrganizationsTotalCount,
            currentPage: state.pagesState.currentPage
        };
    })

    const onClose = () => {
        const action = {
            type: 'CUSTOMER_SELECTION_CLOSED'
        };
        dispatch(action);
    }

    const onSearch = (event) => {
        event.preventDefault();
        if (searchText.length > 2) {
            const action = {
                type: 'CUSTOMER_SELECTION_SEARCH_STARTED'
            };
            dispatch(action);

            CustomerService.searchOrganization({
                keywords: searchText,
                size: size,
                from: 0
            })
                .then((result) => {
                    const action = {
                        type: 'CUSTOMER_SELECTION_FOUND',
                        foundOrganizations: result.collections,
                        foundOrganizationsTotalCount: result.total
                    };
                    dispatch(action);
                })
                .catch(() => {
                    const action = {
                        type: 'CUSTOMER_SELECTION_SEARCH_FAILED'
                    };
                    dispatch(action);
                })
        }
    }

    const onScroll = () => {
        CustomerService.searchOrganization({
            keywords: searchText,
            size: size,
            from: reduxState.foundOrganizations.length
        })
            .then((result) => {
                const action = {
                    type: 'CUSTOMER_SELECTION_ADDED',
                    addedOrganizations: result.collections
                };
                dispatch(action);
            })
    }

    const onChangeOrganizationCheckbox = (selectedOrganization, checked) => {
        const action = {
            type: 'CUSTOMER_SELECTION_CHECKBOX_CHANGED_SINGLE',
            selectedOrganization: selectedOrganization,
            checked: checked
        };
        dispatch(action);
    }

    const onChangeAllOrganizationsCheckbox = (checked) => {
        const action = {
            type: 'CUSTOMER_SELECTION_CHECKBOX_CHANGED_ALL',
            checked: checked
        };
        dispatch(action);
    }

    const onChangeTab = (tabId) => {
        const action = {
            type: 'CUSTOMER_SELECTION_TAB_CHANGED',
            tabId: tabId
        };
        dispatch(action);
    }

    const onConfirmSearch = () => {
        const action = {
            type: 'CUSTOMER_SELECTION_COMPLETED',
            customers: reduxState.checkedOrganizations,
            currentPage: reduxState.currentPage
        };
        dispatch(action);
    }

    const Puller = styled('div')(({theme}) => ({
        width: 30,
        height: 6,
        backgroundColor: grey[300],
        borderRadius: 3,
        position: 'absolute',
        top: 8,
        left: 'calc(50% - 15px)'
    }));

    return (
        <React.Fragment>
            {
                isMobile ?
                    <SwipeableDrawer
                        anchor='bottom'
                        open={reduxState.openModal}
                        onClose={onClose}
                        PaperProps={{
                            sx: {
                                width: "100%",
                                borderRadius: "16px 16px 0 0",
                                height: "80%"
                            }
                        }}
                        scroll={"paper"}
                    >
                        <Puller/>
                        <DialogTitle
                            sx={{
                                m: 0,
                                padding: '32px 16px 12px',
                                fontSize: "20px!important",
                                fontWeight: "600",
                                fontFamily: "SF Pro Text, sans-serif",
                                letterSpacing: "-0.41px"
                            }}
                            id="customized-dialog-title">
                            Выбор заказчика
                        </DialogTitle>

                        <Grid container spacing="0" alignItems="center" sx={{padding: "0 16px 12px"}}>
                            <form onSubmit={onSearch} style={{width: "100%"}}>
                                <Grid sx={{display: 'flex', gap: "10px", width: "100%"}}>
                                    <ThemeProvider theme={{
                                        components: {
                                            MuiFormControl: {
                                                styleOverrides: {
                                                    root: {
                                                        width: "100%",
                                                    }
                                                }
                                            },
                                            MuiOutlinedInput: {
                                                styleOverrides: {
                                                    root: {
                                                        borderRadius: "4px",
                                                        fontSize: "16px",
                                                        width: "100%",
                                                        fontFamily: "SF Pro Text, serif",
                                                        letterSpacing: "-0.41px",
                                                        '& label.Mui-focused': {
                                                            color: 'var(--TextField-brandBorderFocusedColor)',
                                                        },
                                                    },
                                                }
                                            }
                                        }
                                    }}>
                                        <TextField
                                            autoFocus
                                            size="small"
                                            onChange={(event) => {
                                                setSearchText(event.target.value);
                                            }}
                                        />
                                    </ThemeProvider>
                                    <Button
                                        sx={{
                                            borderRadius: '6px',
                                            backgroundColor: '#2B65F0',
                                            padding: '6px 18px',
                                            fontFamily: 'SF Pro Text, serif',
                                            fontSize: "16px",
                                            textTransform: "unset!important",
                                            letterSpacing: "-0.41px",
                                            lineHeight: "unset!important",
                                            fontWeight: "500",
                                            '&:hover': {
                                                backgroundColor: "#3060DC!important"
                                            }
                                        }}
                                        variant="contained"
                                        type="submit">
                                        Найти
                                    </Button>
                                </Grid>
                            </form>
                        </Grid>

                        <DialogContent sx={{borderBottom: "0.5px solid #E8E8E8", paddingTop: "12px", p: 0}}>
                            <TabContext value={reduxState.activeTabId}>
                                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                    <Grid container sx={{padding: "0 16px"}}>
                                        <Grid item xs={11}>
                                            <ThemeProvider
                                                theme={{
                                                    components: {
                                                        MuiTabs: {
                                                            styleOverrides: {
                                                                root: {
                                                                    paddingLeft: "0px!important",
                                                                    paddingRight: "0px!important"
                                                                }
                                                            }
                                                        },
                                                        MuiTab: {
                                                            styleOverrides: {
                                                                root: {
                                                                    fontSize: "15px",
                                                                    textTransform: "unset",
                                                                    fontFamily: "SF Pro Text, sans-serif",
                                                                    letterSpacing: "-0.41px",
                                                                    fontWeight: "400",
                                                                    padding: "12px 6px",
                                                                    '&.Mui-selected': {
                                                                        color: "#000000"
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }}
                                            >
                                                <TabList
                                                    classes={{
                                                        indicator: styles.indicator
                                                    }}
                                                    sx={{pr: 3, pl: 3}}
                                                    onChange={(event, newValue) => {
                                                        onChangeTab(newValue);
                                                    }}>
                                                    <Tab
                                                        label={
                                                            <span>Найдено {reduxState.foundOrganizationsTotalCount > 0 ?
                                                                <b>{`${reduxState.foundOrganizationsTotalCount}`}</b> : ''}</span>}
                                                        value={SearchResultsTabId}/>
                                                    <Tab
                                                        label={
                                                            <span>Выбрано {reduxState.checkedOrganizations.length > 0 ?
                                                                <b>{`${reduxState.checkedOrganizations.length}`}</b> : ''}</span>}
                                                        value={CheckedTabId}/>
                                                </TabList>
                                            </ThemeProvider>
                                        </Grid>
                                        {
                                            reduxState.activeTabId === CheckedTabId
                                                ?
                                                <Grid item xs={1} sx={{display: "flex"}}>
                                                    <BpCheckbox
                                                        onClick={(event) => {
                                                            const checked = event.target.checked;
                                                            onChangeAllOrganizationsCheckbox(checked);
                                                        }}
                                                        checked={
                                                            (reduxState.activeTabId === SearchResultsTabId && reduxState.foundOrganizations.length > 0 && reduxState.foundOrganizations.every((item) => reduxState.checkedOrganizations.some((org) => org.id === item.id)))
                                                            ||
                                                            (reduxState.activeTabId === CheckedTabId && reduxState.checkedOrganizations.length > 0)
                                                        }

                                                    />
                                                </Grid>
                                                :
                                                null
                                        }
                                    </Grid>
                                </Box>
                                <TabPanel value={SearchResultsTabId} sx={{overflowY: "scroll", p: 0, pt: 2}}
                                          id={infiniteScrollElementDomName}>
                                    {
                                        reduxState.isLoading
                                            ?
                                            <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                                                <CircularProgress/>
                                            </div>
                                            :
                                            <div>
                                                <InfiniteScroll
                                                    dataLength={reduxState.foundOrganizations.length}
                                                    next={onScroll}
                                                    hasMore={reduxState.foundOrganizations.length < reduxState.foundOrganizationsTotalCount}
                                                    loader={<h6>Загрузка...</h6>}
                                                    scrollThreshold={0.9}
                                                    scrollableTarget={infiniteScrollElementDomName}
                                                >
                                                    {reduxState.foundOrganizations.map((organization, key) => (
                                                        <Grid container key={key} className={styles.organizationItem}>
                                                            <Grid item xs={11}>
                                                                <OrganizationCard organization={organization}/>
                                                            </Grid>
                                                            <Grid item xs={1} sx={{display: "flex"}}>
                                                                <BpCheckbox
                                                                    onChange={(event) => {
                                                                        const checked = event.target.checked;
                                                                        onChangeOrganizationCheckbox(organization, checked);
                                                                    }}
                                                                    checked={reduxState.checkedOrganizations.some((org) => org.id === organization.id)}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                                </InfiniteScroll>
                                            </div>
                                    }

                                </TabPanel>
                                <TabPanel value={CheckedTabId} sx={{overflowY: "scroll", p: 0, pt: 2}}>
                                    <Grid container>
                                        {reduxState.checkedOrganizations.map((organization, key) => (
                                            <Grid container key={key} className={styles.organizationItem}>
                                                <Grid item xs={11}>
                                                    <OrganizationCard organization={organization}/>
                                                </Grid>
                                                <Grid item xs={1} sx={{display: "flex"}}>
                                                    <BpCheckbox
                                                        onChange={(event) => {
                                                            const checked = event.target.checked;
                                                            onChangeOrganizationCheckbox(organization, checked);
                                                        }}
                                                        checked={reduxState.checkedOrganizations.some((org) => org.id === organization.id)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </TabPanel>
                            </TabContext>
                        </DialogContent>


                        <DialogActions sx={{justifyContent: 'flex-start', pt: 2, pb: 3, paddingLeft: '16px'}}>
                            <Button
                                sx={{
                                    borderRadius: '10px',
                                    backgroundColor: '#2B65F0',
                                    padding: '10px 18px',
                                    width: '120px',
                                    fontFamily: 'SF Pro Text, serif',
                                    fontSize: "16px",
                                    textTransform: "unset!important",
                                    letterSpacing: "-0.41px",
                                    lineHeight: "unset!important",
                                    fontWeight: "500",
                                    '&:hover': {
                                        backgroundColor: "#3060DC!important"
                                    }
                                }}
                                variant="contained"
                                onClick={onConfirmSearch}>Принять</Button>
                            <Button
                                sx={{
                                    borderRadius: '10px',
                                    padding: '10px 18px',
                                    fontFamily: 'SF Pro Text, serif',
                                    fontSize: "16px",
                                    textTransform: "unset!important",
                                    letterSpacing: "-0.41px",
                                    lineHeight: "unset!important",
                                    color: "#000000",
                                    fontWeight: "400"
                                }}
                                startIcon={<img src="/close_icon.svg" alt="Close"/>} variant="text"
                                onClick={onClose}>Отмена</Button>
                        </DialogActions>
                    </SwipeableDrawer> :
                    <Dialog
                        open={reduxState.openModal}
                        onClose={onClose}
                        PaperProps={{
                            sx: {
                                maxWidth: "600px",
                                width: "700px",
                                borderRadius: "16px",
                                maxHeight: "80%",
                                minHeight: "80%"
                            }
                        }}
                        scroll={"paper"}
                    >
                        <DialogTitle sx={{
                            m: 0,
                            p: '24px 24px 0 24px',
                            fontSize: "22px!important",
                            fontWeight: "600",
                            fontFamily: "SF Pro Text, serif",
                            letterSpacing: "-0.41px"
                        }} id="customized-dialog-title">
                            Выбор заказчика
                        </DialogTitle>
                        <IconButton
                            aria-label="close"
                            onClick={onClose}
                            sx={(theme) => ({
                                position: 'absolute',
                                right: 16,
                                top: 22,
                                color: theme.palette.grey[500],
                            })}
                        >
                            <img src="/close_icon.svg" alt="Close"/>
                        </IconButton>

                        <Grid container spacing="0" alignItems="center" sx={{p: 3, pb: "12px"}}>
                            <form onSubmit={onSearch} style={{width: "100%"}}>
                                <Grid sx={{display: 'flex', gap: "10px", width: "100%"}}>
                                    <ThemeProvider theme={{
                                        components: {
                                            MuiFormControl: {
                                                styleOverrides: {
                                                    root: {
                                                        width: "100%",
                                                    }
                                                }
                                            },
                                            MuiOutlinedInput: {
                                                styleOverrides: {
                                                    root: {
                                                        borderRadius: "4px",
                                                        fontSize: "14px",
                                                        width: "100%",
                                                        fontFamily: "SF Pro Text, serif",
                                                        letterSpacing: "-0.41px",
                                                        '& label.Mui-focused': {
                                                            color: 'var(--TextField-brandBorderFocusedColor)',
                                                        },
                                                    },
                                                }
                                            }
                                        }
                                    }}>
                                        <TextField
                                            autoFocus
                                            size="small"
                                            onChange={(event) => {
                                                setSearchText(event.target.value);
                                            }}
                                        />
                                    </ThemeProvider>
                                    <Button
                                        sx={{
                                            borderRadius: '6px',
                                            backgroundColor: '#2B65F0',
                                            padding: '6px 18px',
                                            fontFamily: 'SF Pro Text, serif',
                                            fontSize: "14px",
                                            textTransform: "unset!important",
                                            letterSpacing: "-0.41px",
                                            lineHeight: "unset!important",
                                            fontWeight: "500",
                                            '&:hover': {
                                                backgroundColor: "#3060DC!important"
                                            }
                                        }}
                                        variant="contained"
                                        type="submit">
                                        Найти
                                    </Button>
                                </Grid>
                            </form>
                        </Grid>

                        <DialogContent sx={{borderBottom: "0.5px solid #E8E8E8", paddingTop: "12px", p: 0}}>
                            <TabContext value={reduxState.activeTabId}>
                                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                    <Grid container>
                                        <Grid item xs={11}>
                                            <ThemeProvider
                                                theme={{
                                                    components: {
                                                        MuiTab: {
                                                            styleOverrides: {
                                                                root: {
                                                                    fontSize: "14px",
                                                                    textTransform: "unset",
                                                                    fontFamily: "SF Pro Text, sans-serif",
                                                                    letterSpacing: "-0.41px",
                                                                    fontWeight: "400",
                                                                    padding: "16px 12px",
                                                                    '&.Mui-selected': {
                                                                        color: "#000000"
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }}
                                            >
                                                <TabList
                                                    classes={{
                                                        indicator: styles.indicator
                                                    }}
                                                    sx={{pr: 3, pl: 3}}
                                                    onChange={(event, newValue) => {
                                                        onChangeTab(newValue);
                                                    }}>
                                                    <Tab
                                                        label={
                                                            <span>Найдено {reduxState.foundOrganizationsTotalCount > 0 ?
                                                                <b>{`${reduxState.foundOrganizationsTotalCount}`}</b> : ''}</span>}
                                                        value={SearchResultsTabId}/>
                                                    <Tab
                                                        label={
                                                            <span>Выбрано {reduxState.checkedOrganizations.length > 0 ?
                                                                <b>{`${reduxState.checkedOrganizations.length}`}</b> : ''}</span>}
                                                        value={CheckedTabId}/>
                                                </TabList>
                                            </ThemeProvider>
                                        </Grid>
                                        {
                                            reduxState.activeTabId === CheckedTabId
                                                ?
                                                <Grid item xs={1} sx={{display: "flex"}}>
                                                    <BpCheckbox
                                                        onClick={(event) => {
                                                            const checked = event.target.checked;
                                                            onChangeAllOrganizationsCheckbox(checked);
                                                        }}
                                                        checked={
                                                            (reduxState.activeTabId === SearchResultsTabId && reduxState.foundOrganizations.length > 0 && reduxState.foundOrganizations.every((item) => reduxState.checkedOrganizations.some((org) => org.id === item.id)))
                                                            ||
                                                            (reduxState.activeTabId === CheckedTabId && reduxState.checkedOrganizations.length > 0)
                                                        }

                                                    />
                                                </Grid>
                                                :
                                                null
                                        }
                                    </Grid>
                                </Box>
                                <TabPanel value={SearchResultsTabId} sx={{overflowY: "scroll", p: 0, pt: 2}}
                                          id={infiniteScrollElementDomName}>
                                    {
                                        reduxState.isLoading
                                            ?
                                            <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                                                <CircularProgress/>
                                            </div>
                                            :
                                            <div>
                                                <InfiniteScroll
                                                    dataLength={reduxState.foundOrganizations.length}
                                                    next={onScroll}
                                                    hasMore={reduxState.foundOrganizations.length < reduxState.foundOrganizationsTotalCount}
                                                    loader={<h6>Загрузка...</h6>}
                                                    scrollThreshold={0.9}
                                                    scrollableTarget={infiniteScrollElementDomName}
                                                >
                                                    {reduxState.foundOrganizations.map((organization, key) => (
                                                        <Grid container key={key} className={styles.organizationItem}>
                                                            <Grid item xs={11}>
                                                                <OrganizationCard organization={organization}/>
                                                            </Grid>
                                                            <Grid item xs={1} sx={{display: "flex"}}>
                                                                <BpCheckbox
                                                                    onChange={(event) => {
                                                                        const checked = event.target.checked;
                                                                        onChangeOrganizationCheckbox(organization, checked);
                                                                    }}
                                                                    checked={reduxState.checkedOrganizations.some((org) => org.id === organization.id)}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                                </InfiniteScroll>
                                            </div>
                                    }

                                </TabPanel>
                                <TabPanel value={CheckedTabId} sx={{overflowY: "scroll"}}>
                                    <Grid container>
                                        {reduxState.checkedOrganizations.map((organization, key) => (
                                            <Grid container key={key} sx={{display: 'flex', mt: 1, ml: 1}}>
                                                <Grid item xs={11}>
                                                    <OrganizationCard organization={organization}/>
                                                </Grid>
                                                <Grid item xs={1} sx={{display: "flex"}}>
                                                    <BpCheckbox
                                                        onChange={(event) => {
                                                            const checked = event.target.checked;
                                                            onChangeOrganizationCheckbox(organization, checked);
                                                        }}
                                                        checked={reduxState.checkedOrganizations.some((org) => org.id === organization.id)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </TabPanel>
                            </TabContext>
                        </DialogContent>


                        <DialogActions sx={{justifyContent: 'flex-start', pt: 2, pb: 2, paddingLeft: '24px'}}>
                            <Button
                                sx={{
                                    borderRadius: '10px',
                                    backgroundColor: '#2B65F0',
                                    padding: '10px 18px',
                                    width: '120px',
                                    fontFamily: 'SF Pro Text, serif',
                                    fontSize: "16px",
                                    textTransform: "unset!important",
                                    letterSpacing: "-0.41px",
                                    lineHeight: "unset!important",
                                    fontWeight: "500",
                                    '&:hover': {
                                        backgroundColor: "#3060DC!important"
                                    }
                                }}
                                variant="contained"
                                onClick={onConfirmSearch}>Принять</Button>
                            <Button
                                sx={{
                                    borderRadius: '10px',
                                    padding: '10px 18px',
                                    fontFamily: 'SF Pro Text, serif',
                                    fontSize: "16px",
                                    textTransform: "unset!important",
                                    letterSpacing: "-0.41px",
                                    lineHeight: "unset!important",
                                    color: "#000000",
                                    fontWeight: "400"
                                }}
                                startIcon={<img src="/close_icon.svg" alt="Close"/>} variant="text"
                                onClick={onClose}>Отмена</Button>
                        </DialogActions>
                    </Dialog>
            }
        </React.Fragment>
    )
}
