import React, { Component } from 'react';
import UserManager from '../../userManager';

class LogoutCallback extends Component {

  constructor(props) {
    super(props);
  }
  
  render() {
    UserManager.signoutRedirectCallback().then(user => {
		window.location.href = "/purchases";
	}).catch(function (e) {
		console.error(e);
	});
	return null;
  }
}
export default LogoutCallback;
