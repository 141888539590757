class CustomerService {
  async getCustomer(customerOrgId) {
    if (customerOrgId)
    {
      var baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558';    
      const user = JSON.parse(localStorage.getItem(`oidc.user:${baseIdentityURL}:web_client`));

      const requestOptions = {
        method: 'GET',
        headers: { 
          'Content-Type': 'application/json', 
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + (user ? user.access_token : ''),
        }
      };

      const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_ORGANIZATIONS_API_URI : 'http://localhost:5561';
      const response = await fetch(`${baseURL}/api/organizations/${customerOrgId}`, requestOptions);
      if (response.status === 200)
      {
        const customer = await response.json();
        return customer;
      }
    }
    return null;
  }

  async getOrganizationByInn(organizationInn){
    if (organizationInn) {
      var baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558';    
      const user = JSON.parse(localStorage.getItem(`oidc.user:${baseIdentityURL}:web_client`));

      const requestOptions = {
        method: 'GET',
        headers: { 
          'Content-Type': 'application/json', 
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + (user ? user.access_token : ''),
        }
      };

      const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_ORGANIZATIONS_API_URI : 'http://localhost:5561';
      const response = await fetch(`${baseURL}/api/organizations/inn/${organizationInn}`, requestOptions);
      if (response.status === 200)
      {
        const customer = await response.json();
        return customer;
      }
    }
    return null;
  }

  async searchOrganization(options) {
    var baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558';  
    const user = JSON.parse(localStorage.getItem(`oidc.user:${baseIdentityURL}:web_client`));

    const requestOptions = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + (user ? user.access_token : ''),
      },
      body: JSON.stringify(options)
    };

    const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_ORGANIZATIONS_API_URI : 'http://localhost:5561';
    const response = await fetch(`${baseURL}/api/organizations/search`, requestOptions);
    if (response.status === 200)
    {
      const organizations = await response.json();
      return organizations;
    }
  }
}

const exportCustomerService = new CustomerService();
export default exportCustomerService;