import React, { Component } from 'react';
import UserManager from '../../userManager';

class SignInCallback extends Component {

  constructor(props) {
    super(props);
  }
  
  render() {
	//UserManager.clearStaleState();
	UserManager.signinRedirectCallback().then(user => {
		console.log('signing redirect callback going nice');
		window.location.href = "/purchases";
	})
	.catch(function (e) {
		console.error(e);
	});
	return null;
  }
}
export default SignInCallback;
