import React from 'react';
import { withRouter } from '../withRouter';
import styles from '../css/PurchaseSearchResult/PurchaseSearchResult.module.css'

//MUI
import {Rating, Box} from '@mui/material';

class CustomerReviewInfo extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Box className={styles.reviewContainer}>
            <Box className={styles.reviewInnerContainer}>
              <span className={styles.reviewText}>
                {this.props.review.text}
              </span>

              <span className={styles.reviewAuthor}>
                Автор отзыва: {this.props.review.author}
              </span>
            </Box>

          <Rating value={this.props.review.rating} precision={1} readOnly />
        </Box>
      </React.Fragment>
    )
  }
}

export default withRouter(CustomerReviewInfo);
