// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CustomerSelection_organizationItem__BACWi {\n    transition: 0.2s ease all;\n    background-color: #FFFFFF;\n    display: flex;\n    padding: 8px 24px;\n    cursor: pointer;\n    align-items: center;\n}\n\n.CustomerSelection_organizationItem__BACWi:hover {\n    transition: 0.3s ease all;\n    background-color: #F0F0F0;\n}\n\n.CustomerSelection_indicator__GwfMJ {\n    background-color: #2B65F0!important;\n}\n\n@media screen and (max-width: 1024px) {\n    .CustomerSelection_organizationItem__BACWi {\n        padding: 8px 16px;\n    }\n\n    .CustomerSelection_organizationItem__BACWi:hover {\n        background-color: unset;\n    }\n\n}\n", "",{"version":3,"sources":["webpack://./src/css/CustomerSelection/CustomerSelection.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,aAAa;IACb,iBAAiB;IACjB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;AAC7B;;AAEA;IACI,mCAAmC;AACvC;;AAEA;IACI;QACI,iBAAiB;IACrB;;IAEA;QACI,uBAAuB;IAC3B;;AAEJ","sourcesContent":[".organizationItem {\n    transition: 0.2s ease all;\n    background-color: #FFFFFF;\n    display: flex;\n    padding: 8px 24px;\n    cursor: pointer;\n    align-items: center;\n}\n\n.organizationItem:hover {\n    transition: 0.3s ease all;\n    background-color: #F0F0F0;\n}\n\n.indicator {\n    background-color: #2B65F0!important;\n}\n\n@media screen and (max-width: 1024px) {\n    .organizationItem {\n        padding: 8px 16px;\n    }\n\n    .organizationItem:hover {\n        background-color: unset;\n    }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"organizationItem": "CustomerSelection_organizationItem__BACWi",
	"indicator": "CustomerSelection_indicator__GwfMJ"
};
export default ___CSS_LOADER_EXPORT___;
