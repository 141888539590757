class PurchaseService {
    async search(options) {
      const baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558';
      const user = JSON.parse(localStorage.getItem(`oidc.user:${baseIdentityURL}:web_client`));

      const requestOptions = {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json', 
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + (user ? user.access_token : ''),
        },
        body: JSON.stringify(options)
      };
  
      const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_API_URI : 'http://localhost:5552';
      const response = await fetch(`${baseURL}/api/purchases/search`, requestOptions);
      const purchases = await response.json();
      return purchases;
    }

    async getPurchaseClusters(options) {
      var baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558';
      const user = JSON.parse(localStorage.getItem(`oidc.user:${baseIdentityURL}:web_client`));

      const requestOptions = {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json', 
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + (user ? user.access_token : ''),
        },
        body: JSON.stringify(options)
      };
  
      const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_API_URI : 'http://localhost:5552';
      const response = await fetch(`${baseURL}/api/purchases/geo-clusters`, requestOptions);
      const purchases = await response.json();
      return purchases;
    }
  
    async get(id) {
      var baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558';   
      const user = JSON.parse(localStorage.getItem(`oidc.user:${baseIdentityURL}:web_client`));

      const requestOptions = {
        method: 'GET',
        headers: { 
          'Content-Type': 'application/json', 
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + (user ? user.access_token : ''),
        }
      };
  
      const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_API_URI : 'http://localhost:5552';
      const response = await fetch(`${baseURL}/api/purchases/${id}`, requestOptions);
      const purchase = await response.json();
      return purchase;
    }

    async getByOrganizationId(request) {
      var baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558';     
      const user = JSON.parse(localStorage.getItem(`oidc.user:${baseIdentityURL}:web_client`));

      const requestOptions = {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json', 
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + (user ? user.access_token : ''),
        },
        body: JSON.stringify(request)
      };
  
      const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_API_URI : 'http://localhost:5552';
      const response = await fetch(`${baseURL}/api/purchases/organization`, requestOptions);
      const purchase = await response.json();
      return purchase;
    }
    
    async getMany(ids) {
      var baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558';    
      const user = JSON.parse(localStorage.getItem(`oidc.user:${baseIdentityURL}:web_client`));

      const requestOptions = {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json', 
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + (user ? user.access_token : ''),
        },
        body: JSON.stringify({ ids }),
      };
  
      const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_API_URI : 'http://localhost:5552';
      
      const response = await fetch(`${baseURL}/api/purchases/search`, requestOptions);
      const result = await response.json();

      if (response.status == 200) {  
        return result;
      } else {
        console.error('PurchaseService > getMany > ', result);
        return {
          hasError: true
        };
      }
    }

    async searchSuggestions(keywords) {
      var baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558';     
      const user = JSON.parse(localStorage.getItem(`oidc.user:${baseIdentityURL}:web_client`));

      const requestOptions = {
        method: 'GET',
        headers: { 
          'Content-Type': 'application/json', 
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + (user ? user.access_token : ''),
        }
      };
  
      const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_API_URI : 'http://localhost:5552';
      const response = await fetch(`${baseURL}/api/suggestions?phrase=${keywords}`, requestOptions);
      const suggestions = await response.json();
      return suggestions;
    }

    async getByIds(ids) {
      var baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558';    
      const user = JSON.parse(localStorage.getItem(`oidc.user:${baseIdentityURL}:web_client`));

      const requestOptions = {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json', 
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + (user ? user.access_token : ''),
        },
        body: JSON.stringify(ids)
      };
  
      const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_API_URI : 'http://localhost:5552';
      const response = await fetch(`${baseURL}/api/purchases/byids`, requestOptions);
      const purchase = await response.json();
      return purchase;      
    }
  }
  
  export default new PurchaseService();