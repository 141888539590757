export const PurchaseTypes = [
    {
        id: 1,
        name: "44-ФЗ"
    },
    {
        id: 2,
        name: "223-ФЗ"
    },
    {
        id: 3,
        name: "Коммерческие"
    },
    {
        id: 4,
        name: "ПП РФ 615"
    },
]
