const initialState = {
  southWestLat: -90,
  southWestLng: -180,
  northEastLat: 90,
  northEastLng: 180,
  zoom: 4,
  purchases: [],
  purchaseClusters: [],
  isDrawerOpened: false,
}

const reducer = (state = initialState, action) => {
  if (action.type === 'MAP_INITIATED') {
    return initialState;
  }

  if (action.type === 'MAP_STATE_CHANGED') {
    return {
      ...state,
      southWestLat: action.southWest.lat,
      southWestLng: action.southWest.lng,
      northEastLat: action.northEast.lat,
      northEastLng: action.northEast.lng,
      zoom: action.zoom
    }
  }

  if (action.type === 'MAP_SET_PURCHASE_CLUSTERS') {
    return {
      ...state, 
      purchaseClusters: action.purchaseClusters
    }
  }

  if (action.type === 'MAP_SET_PURCHASES') {
    return {
      ...state,
      purchases: action.purchases,
      isDrawerOpened: action.purchases.length > 0 ? true : false
    }
  }

  if (action.type === 'MAP_CLOSE_DRAWER') {
    return {
      ...state,
      isDrawerOpened: false
    }
  }

  return state;
}

export default reducer;