import React, {Component} from 'react';
import {withRouter} from '../withRouter';
import styles from '../css/PurchaseLotDetails/PurchaseLotDetails.module.css'

// MUI:
import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from '@mui/material';

class PurchaseLotDetails extends Component {
    render() {
        return (
            <React.Fragment>
                <Box className={styles.container}>
                    <span className={styles.title}>Список лотов</span>
                    <Box>
                        <TableContainer className={styles.tableContainer}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{width: "75%", paddingLeft: "0"}}>Наименование</TableCell>
                                        <TableCell style={{width: "15%"}}>Стоимость</TableCell>
                                        <TableCell style={{width: "10%"}}>Количество</TableCell>
                                    </TableRow>
                                </TableHead>
                                {this.props.lots.map((lot, key) =>
                                        <TableBody key={key}>
                                            <TableRow sx={{"& td": {border: 0}}}>
                                                <TableCell style={{paddingLeft: "0"}} className={styles.nameCell}>
                                <span>
                                    {lot.title}
                                </span>

                                                    <div className={styles.nameCellOkpd}>
                                                        {lot.okpdCodes.map((okpd, key) =>
                                                            <div key={key}>
                                                                {okpd.code} {okpd.name}
                                                            </div>
                                                        )}
                                                        {lot.ktruCodes.map((ktru, key) =>
                                                            <div key={key}>
                                                                {ktru.code} {ktru.name}
                                                            </div>
                                                        )}
                                                        {lot.okpdCode} {lot.okpdName} {lot.ktruCode} {lot.ktruName}
                                                    </div>
                                                </TableCell>
                                                <TableCell>{lot.price ? lot.price + ' ₽' : '—'}</TableCell>
                                                <TableCell>{lot.quantity}</TableCell>
                                            </TableRow>


                                            {lot.lotItemsType === 1 &&
                                                <React.Fragment>
                                                    <TableRow sx={{"& td": {border: 0}}}>
                                                        <TableCell colSpan={3}>
                                                            <Box>
                                                                <Typography variant="caption" component="div" align="center"
                                                                            sx={{
                                                                                fontFamily: "SF Pro Text, sans-serif",
                                                                                letterSpacing: "-0.41px"
                                                                            }}>
                                                                    <b>Составляющие лота:</b>
                                                                </Typography>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>

                                                    {lot.lotItems.map((lotItem, key) =>
                                                        <TableRow key={key} sx={{"& td": {border: 0}}}>
                                                            <TableCell className={styles.nameCell}>
                                                                <span>{lotItem.title}</span>

                                                                <div className={styles.nameCellOkpd}>
                                                                    {lotItem.okpdCodes.map((okpd, key) =>
                                                                        <div key={key}>
                                                                            {okpd.code} {okpd.name}
                                                                        </div>
                                                                    )}
                                                                    {lotItem.ktruCodes.map((ktru, key) =>
                                                                        <div key={key}>
                                                                            {ktru.code} {ktru.name}
                                                                        </div>
                                                                    )}
                                                                    {lotItem.okpdCode} {lotItem.okpdName} {lotItem.ktruCode} {lotItem.ktruName}
                                                                </div>
                                                            </TableCell>
                                                            <TableCell>{lotItem.price ? lotItem.price + ' ₽' : '—'}</TableCell>
                                                            <TableCell>{lotItem.quantity}</TableCell>
                                                        </TableRow>
                                                    )}
                                                </React.Fragment>
                                            }


                                            {lot.lotItemsType === 2 &&
                                                <React.Fragment>
                                                    <TableRow sx={{"& td": {border: 0}}}>
                                                        <TableCell colSpan={3}>
                                                            <Box>
                                                                <Typography variant="caption" component="div"
                                                                            align="center">
                                                                    <b>Альтернативы лота:</b>
                                                                </Typography>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                    {
                                                        lot.lotItems.map((lotItem, key) =>
                                                            <TableRow key={key} sx={{"& td": {border: 0}}}>
                                                                <TableCell className={styles.nameCell}>
                                                                    <span>{lotItem.title}</span>
                                                                    <div className={styles.nameCellOkpd}>
                                                                        {lotItem.okpdCode} {lotItem.okpdName} {lotItem.ktruCode} {lotItem.ktruName}
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell>{lotItem.price ? lotItem.price + ' ₽' : '—'}</TableCell>
                                                                <TableCell>{lotItem.quantity}</TableCell>
                                                            </TableRow>
                                                        )}
                                                </React.Fragment>
                                            }
                                        </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </React.Fragment>
        )
    }
}

export default withRouter(PurchaseLotDetails);
