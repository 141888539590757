import React, { Component } from 'react';

export class Logout extends Component {

  constructor(props) {
    super(props);
	this.logout();
  }
  
  getLogoutIdFromQuery() {
	const query = new URLSearchParams(window.location.search);
	var logoutId = query.get('logoutId');
	return logoutId;
  }
  
  logout() {
	const requestOptions = {
		method: 'POST',
		headers: { 
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			logoutId: this.getLogoutIdFromQuery(),
		}),
		credentials: 'include'
	};
	const baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558';
	fetch(baseIdentityURL + "/api/account/logout/", requestOptions)
		.then(res => res.json())
		.then((response) => {
			if (response.successful) {
				console.log('Пользователь разлогинен!');
				window.location.replace(response.postLogoutRedirectUri);
			}
		},
		(error) => {
			console.log('Произошла ошибка при попытке разлогиниться'+error);
		});
  }

  render() { return (
	<div></div>
  )}
}
export default Logout;