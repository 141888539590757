import React, { Component } from 'react';
import UserManager from '../../userManager';
import styles from "../../css/Header/Header.module.css"

class AuthHeader extends Component {

  constructor(props) {
    super(props);
	
    var baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558';
    const user = JSON.parse(localStorage.getItem(`oidc.user:${baseIdentityURL}:web_client`));

    this.userProfile = user ? user.profile : null;
	
	  this.logout = this.logout.bind(this);
  }
  
  logout() {
	  UserManager.signoutRedirect();
  }
  
  render() {
	return (
        <div className={styles.logoutContainer}>
            <div className={styles.logoutInnerContainer}>
                <img src="/profile-circle.svg" alt="profile"/> {this.userProfile ? this.userProfile.phone_number : ''}
            </div>
            <button onClick={this.logout} className={styles.logoutButton}>Выйти</button>
        </div>
    )
  }
}

export default AuthHeader;
