// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AuthFooter_container__YK46W {\n    width: 100%;\n    letter-spacing: -0.41px;\n    font-family: \"SF Pro Text\", sans-serif;\n    color: #585E63;\n    margin-bottom: 32px;\n}\n\n.AuthFooter_containerInner__cQOVy {\n    max-width: 1100px;\n    margin: 32px auto 0;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n}\n\n.AuthFooter_copyrights__iAjug {\n    font-size: 13px;\n}\n\n.AuthFooter_policyLink__2XUFL a {\n    font-size: 12px;\n    text-decoration: none;\n    color: #585E63;\n}\n", "",{"version":3,"sources":["webpack://./src/css/AuthFooter/AuthFooter.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,uBAAuB;IACvB,sCAAsC;IACtC,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,qBAAqB;IACrB,cAAc;AAClB","sourcesContent":[".container {\n    width: 100%;\n    letter-spacing: -0.41px;\n    font-family: \"SF Pro Text\", sans-serif;\n    color: #585E63;\n    margin-bottom: 32px;\n}\n\n.containerInner {\n    max-width: 1100px;\n    margin: 32px auto 0;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n}\n\n.copyrights {\n    font-size: 13px;\n}\n\n.policyLink a {\n    font-size: 12px;\n    text-decoration: none;\n    color: #585E63;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AuthFooter_container__YK46W",
	"containerInner": "AuthFooter_containerInner__cQOVy",
	"copyrights": "AuthFooter_copyrights__iAjug",
	"policyLink": "AuthFooter_policyLink__2XUFL"
};
export default ___CSS_LOADER_EXPORT___;
