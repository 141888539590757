import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from '../withRouter';
import {Route, Routes} from 'react-router-dom';
import PasswordResetByEmail from './Authentication/password-reset-by-email';
import {PasswordReset} from './Authentication/password-reset';
import {PasswordResetSuccess} from './Authentication/password-reset-success';
import {MapSearchPage} from './Map/map-search-page';
import PurchaseSearchPanel from './PurchaseSearchPanel';
import PurchaseDetails from './PurchaseDetails';
import Organizations from './Organizations/Organizations';
import Templates from './Templates';
import SignIn from './Authentication/Signin';
import SignUp from './Authentication/Signup';
import Logout from './Authentication/Logout';
import SignInCallback from './Authentication/SigninCallback';
import SilentRenewCallback from './Authentication/SilentRenewCallback';
import LogoutCallback from './Authentication/LogoutCallback';
import AuthLayout from './Authentication/AuthLayout';
import CustomerReview from './CustomerReviewForm/CustomerReview';
import PurchaseTransportLogs from './PurchaseTransportLogs';
import Tags from './Tags';

class RouteContainer extends Component {
    constructor(props) {
        super(props);
    }

    transportPurchaseLogsPage = (props) => {
        var isShow = false;

        if (Array.isArray(props.permissions)) {
            if (props.permissions.some(permission => permission.indexOf('admin.unpub') > -1)) {
                isShow = true;
            }
        } else if (typeof props.permissions === 'string') {
            if (props.permissions.indexOf('admin.unpub') > -1) {
                isShow = true;
            }
        }

        if (isShow) {
            return <Route path="purchases/logs" element={<PurchaseTransportLogs/>}/>;
        }
    }

    render() {
        return (
            <Routes>
                <Route element={<AuthLayout/>}>
                    <Route index path="purchases" element={<PurchaseSearchPanel/>}/>
                    <Route path="purchases/:purchaseId" element={<PurchaseDetails/>}/>
                    <Route path="purchases/templates" element={<Templates/>}/>
                    {this.transportPurchaseLogsPage(this.props)}
                    <Route path="tags" element={<Tags/>}/>
                    <Route path="organizations" element={<Organizations/>}/>
                </Route>
                <Route path='signin' element={<SignIn/>}/>
                <Route path='signup' element={<SignUp/>}/>
                <Route path='logout' element={<Logout/>}/>
                <Route path='signin-callback' element={<SignInCallback/>}/>
                <Route path='logout-callback' element={<LogoutCallback/>}/>
                <Route path='silent-renew-callback' element={<SilentRenewCallback/>}/>
                <Route path='review/:reviewRequestId' element={<CustomerReview/>}/>
                <Route path='password-reset-by-email' element={<PasswordResetByEmail/>}/>
                <Route path='password-reset' element={<PasswordReset/>}/>
                <Route path='password-reset-success' element={<PasswordResetSuccess/>}/>
                <Route path='map-search-page' element={< MapSearchPage/>}/>
            </Routes>
        )
    }
}

function mapStateToProps(state) {
    return {
        permissions: state.profileState.permissions
    }
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RouteContainer));
