import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {MenuItem, Select} from '@mui/material';

export const PurchaseSearchResultSortingPanel = (props) => {

    const dispatch = useDispatch();
    const reduxState = useSelector((state) => {
        if (state.pagesState.currentPage === 'PURCHASES') {
            return {
                sortBy: state.purchasesState.sortBy,
                ascending: state.purchasesState.ascending,
                currentPage: state.pagesState.currentPage
            };
        }


        if (state.pagesState.currentPage === 'TEMPLATES') {
            return {
                sortBy: state.templatesState.sortBy,
                ascending: state.templatesState.ascending,
                currentPage: state.pagesState.currentPage
            };
        }
    })

    const options = [
        {title: 'Дата публикации по возрастанию', value: '{"sortBy":\"purchaseCreateAt\","ascending":true}'},
        {title: 'Дата публикации по убыванию', value: '{"sortBy":\"purchaseCreateAt\","ascending":false}'},
        {
            title: 'Дата окончания подачи заявок по возрастанию',
            value: '{"sortBy":\"purchaseExpirationAt\","ascending":true}'
        },
        {
            title: 'Дата окончания подачи заявок по убыванию',
            value: '{"sortBy":\"purchaseExpirationAt\","ascending":false}'
        },
        {title: 'Цена по возрастанию', value: '{"sortBy":\"price\","ascending":true}'},
        {title: 'Цена по убыванию', value: '{"sortBy":\"price\","ascending":false}'}
    ]

    return (
        <React.Fragment>
            <Select
                value={JSON.stringify({sortBy: reduxState.sortBy, ascending: reduxState.ascending})}
                size="small"
                onChange={(event) => {
                    const value = event.target.value;
                    const action = {
                        type: 'SORT_BY_CHANGED',
                        value: value,
                        currentPage: reduxState.currentPage
                    }
                    dispatch(action);
                }}
                sx={{
                    mb: 2,
                    maxWidth: "300px",
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderRadius: "8px"
                    }
                }}
            >
                {options.map((option, key) =>
                    <MenuItem value={option.value} key={key}
                              sx={{fontFamily: "SF Pro Text, sans-serif", letterSpacing: "-0.41px"}}
                    >{option.title}</MenuItem>
                )}
            </Select>
        </React.Fragment>
    )
}
