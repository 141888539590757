const initialState = {
  currentPage: 'PURCHASES'
}

const reducer = (state = initialState, action) => {
  if (action.type === 'PURCHASE_PAGE') {
    return {
      ...state,
      currentPage: 'PURCHASES'
    }
  }
  
  if (action.type === 'TEMPLATE_PAGE') {
    return {
      ...state,
      currentPage: 'TEMPLATES'
    }    
  }

  if (action.type === 'ORGANIZATION_PAGE') {
    return {
      ...state,
      currentPage: 'ORGANIZATIONS'
    }
  }

 if (action.type === 'TAGS_PAGE') {
    return {
      ...state,
      currentPage: 'TAGS'
    }    
  }

  if (action.type === 'PURCHASE_LOG_PAGE') {
    return {
      ...state,
      currentPage: 'PURCHASE_LOGS'
    }
  }

  return state;
}

export default reducer;