import React, { Component } from 'react';
import UserManager from '../../userManager';

class SilentRenewCallback extends Component {

  constructor(props) {
    super(props);
  }
  
  render() {
	UserManager.signinSilentCallback().catch(function (error) {
        console.error(error);
    });
	return null;
  }
}
export default SilentRenewCallback;
