const initialState = {
    purchaseTransportLogs: [],
		pageNumber: 0,
		pageSize: 0,
		pageCount: 0,
    total: 0
  }
  
  const reducer = (state = initialState, action) => {
    if (action.type === 'UNPUBLISHED_PURCHASES') {
      return {
        ...state,
        purchaseTransportLogs: action.purchaseTransportLogs,
        pageNumber: action.pageNumber,
        pageSize: action.pageSize,
        pageCount: action.pageCount,
        total: action.total
      }      
    }

    if (action.type === 'EMPTY_UNPUBLISHED_PURCHASES') {
      return {
        ...state,
        purchaseTransportLogs: []
      }
    }
  
    return state;
  }
  
  export default reducer;