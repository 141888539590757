const initialState = {
    permissions: []
  }
  
  const reducer = (state = initialState, action) => {
    if (action.type === 'PERMISSIONS') {
      return {
        ...state,
        permissions: action.permissions
      }
    }
  
    return state;
  }
  
  export default reducer;