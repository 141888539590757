import React, { Component } from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

class SignIn extends Component {

  constructor(props) {
    super(props);
    this.state = { 
		login: '',
		password: '',
		returnUrl: this.getReturnUrlFromQuery(),
		errors: [],
	};

	this.flushErrors = this.flushErrors.bind(this);
	this.pushError = this.pushError.bind(this);
	this.handleSubmit = this.handleSubmit.bind(this);
  }
  
  getReturnUrlFromQuery() {
		const query = new URLSearchParams(window.location.search);
		var returnUrl = query.get('ReturnUrl');
		return returnUrl;
      }
	  
  flushErrors() {
	  this.setState({errors: []});
  }
	  
  pushError(error) {
	  this.setState({errors: [...this.state.errors, error] });
  }
  
  handleSubmit(event) {
	event.preventDefault();
	this.flushErrors();
	const requestOptions = {
                method: 'POST',
				headers: { 
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*', 
				},
				credentials: 'include',
                body: JSON.stringify({
					'username': this.state.login,
					'password': this.state.password,
					'returnUrl': this.state.returnUrl
                })
            };
	const baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558';
	fetch(`${baseIdentityURL}/api/account/signin/`, requestOptions)
				.then(res => res.json())
                .then((response) => {
					if (response.isOk) {
						window.location.replace(response.returnUrl);
					}
					else {
						this.pushError('Не удалось авторизоваться по данному логину и паролю');
					}
                },
                (error) => {
					this.pushError('Произошла ошибка при авторизации');
                })
  }

  render() {
	const errorDivs = this.state.errors.map((err, key) => <Grid item xs={12} sx={{color:'red'}} key={key}>{err}</Grid>);
	return (
			<Container maxWidth="xs">
				<Box
					sx={{
						marginTop: 8,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
				<Typography component="h1" variant="h5">
					Авторизация
				</Typography>
				<Box component="form" onSubmit={this.handleSubmit} sx={{ mt: 1 }}>
					<TextField
						margin="normal"
						required
						fullWidth
						id="login"
						label="Логин"
						name="login"
						autoComplete="login"
						autoFocus
						onChange={(e) => this.setState({login: e.target.value})}
					/>
					<TextField
						margin="normal"
						required
						fullWidth
						name="password"
						label="Пароль"
						type="password"
						id="password"
						autoComplete="current-password"
						onChange={(e) => this.setState({password: e.target.value})}
					/>
					{/*
					<FormControlLabel
						control={<Checkbox value="remember" color="primary" />}
						label="Запомнить меня"
					/>
				*/}
					<Grid item xs={12}>
						{ errorDivs }
					</Grid>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						sx={{ mt: 3, mb: 2 }}
					>
						Войти
					</Button>
					<Grid container>
						<Grid item xs>
							{
								<Link href="/password-reset" variant="body2">
									Забыли пароль?
								</Link>
							}
						</Grid>
						<Grid item>
							<Link href={"/signup?ReturnUrl=" + encodeURIComponent(this.state.returnUrl) } variant="body2">
								{"Регистрация"}
							</Link>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Container>
  )}
}
export default SignIn;
