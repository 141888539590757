import React from 'react'
import { Typography } from '@mui/material'

export const PasswordResetSuccess = () => {
    return (
        <React.Fragment>
            <Typography>
                Пароль успешно изменен
            </Typography>
		</React.Fragment>
    )
}
