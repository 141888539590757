import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../withRouter';
import styles from '../../css/Organizations/Organizations.module.css'
// MUI
import {
    Grid, Card, CardContent, Tab, Typography, Box
} from '@mui/material';



class OrganizationInfo extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.organization)
    {
    return (
      <React.Fragment>
        <Grid container spacing={3} className={styles.containerInfo}>
            <Grid item xs className={styles.gridColumn}>
                <Grid item xs={12} className={styles.gridColumnInner}>
                    <Grid item sx={{ display: 'flex', alignItems: 'center', color: "#8A8A8E" }}>
                        ИНН
                    </Grid>
                    <Grid item>
                        {this.props.organization.inn ? this.props.organization.inn : '–'}
                    </Grid>
                </Grid>
                <Grid item xs={12} className={styles.gridColumnInner}>
                    <Grid item sx={{ display: 'flex', alignItems: 'center', color: "#8A8A8E" }}>
                        КПП
                    </Grid>
                    <Grid item>
                        {this.props.organization.kpp ? this.props.organization.kpp : '–'}
                    </Grid>
                </Grid>

                <Grid item xs={12} className={styles.gridColumnInner}>
                    <Grid item sx={{ display: 'flex', alignItems: 'center', color: "#8A8A8E" }}>
                        ОГРН
                    </Grid>
                    <Grid item>
                        {this.props.organization.ogrn ? this.props.organization.ogrn : '–'}
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs className={styles.gridColumn}>
                <Grid item xs={12} className={styles.gridColumnInner}>
                    <Grid item sx={{ display: 'flex', alignItems: 'center', color: "#8A8A8E" }}>
                        Регион
                    </Grid>
                    <Grid item >
                        {this.props.organization.sourceRegion ? this.props.organization.sourceRegion : '–'}
                    </Grid>
                </Grid>
                <Grid item xs={12} className={styles.gridColumnInner}>
                    <Grid item sx={{ display: 'flex', alignItems: 'center', color: "#8A8A8E" }}>
                        Место нахождения
                    </Grid>
                    <Grid item>
                        {this.props.organization.sourceActualAddress ? this.props.organization.sourceActualAddress : '–'}
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid item sx={{ display: 'flex', alignItems: 'center', color: "#8A8A8E" }}>
                        Почтовый адрес
                    </Grid>
                    <Grid item>
                        {this.props.organization.sourcePostalAddress ? this.props.organization.sourcePostalAddress : '–'}
                    </Grid>
                </Grid>


            </Grid>

            <Grid item xs className={styles.gridColumn}>
                <Grid item xs={12}>
                    <Grid item sx={{ display: 'flex', alignItems: 'center', color: "#8A8A8E" }}>
                        Ответственное лицо
                    </Grid>
                    <Grid item>
                        {this.props.organization.responsiblePerson ? this.props.organization.responsiblePerson : '–'}
                    </Grid>
                </Grid>
            </Grid>


          {this.props.organization.phones &&
              <Grid item xs className={styles.gridColumn}>
                  <Grid item xs={12}>
                      <Grid item sx={{ display: 'flex', alignItems: 'center', color: "#8A8A8E" }}>
                          Телефон
                      </Grid>
                      <Grid item>
                          {this.props.organization.phones.length > 0 ? this.props.organization.phones.map((phone, key) =>
                              <Typography key={key}>{phone}</Typography>
                          ) : '–'}
                      </Grid>
                  </Grid>
              </Grid>
          }

          {this.props.organization.emails &&
              <Grid item xs className={styles.gridColumn}>
                  <Grid item xs={12}>
                      <Grid item sx={{ display: 'flex', alignItems: 'center', color: "#8A8A8E" }}>
                          E-mail:
                      </Grid>
                      <Grid item>
                          {this.props.organization.emails.length > 0 ? this.props.organization.emails.map((email, key) =>
                              <Typography key={key}>{email}</Typography>
                          ) : '–'}
                      </Grid>
                  </Grid>
              </Grid>
          }
        </Grid>
      </React.Fragment>);
    }
    else {
      return (
      <React.Fragment>
      </React.Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    organization: state.organizationsState.organization
  }
}

export default connect(mapStateToProps)(withRouter(OrganizationInfo));
