import React from 'react';
import { Typography } from '@mui/material'; 
import { DateTime } from 'luxon';
import styles from '../../css/AuthFooter/AuthFooter.module.css'

export const AuthFooter = (props) => {
	return (
    <div className={styles.container}>
      <div className={styles.containerInner}>
          <Typography className={styles.copyrights} style={{ fontSize: 'small', textAlign: 'center'}}>
              © {DateTime.now().year ?? ''} Эксперт Центр
          </Typography>
          <Typography className={styles.policyLink} style={{ fontSize: 'small', filter: 'brightness(0.6)', textAlign: 'center' }}>
              <a target="_blank" href={process.env.REACT_APP_IDENTITY_FRONTEND_URI ?? '' + "/personal-data-policy.html"}>Политика обработки персональных данных</a>
          </Typography>
      </div>
    </div>
  );
}
