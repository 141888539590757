const initialState = {
    notifications: []
  }
  
  const reducer = (state = initialState, action) => {
    if (action.type === 'SNACKBAR_NOTIFICATION_SENT') {
      const generatedKey = new Date().getTime() + Math.random();
      return {
        ...state,
        notifications: [
          ...state.notifications,
          { 
            ...action.notification,
            key: generatedKey
          }
        ]
      }
    }
  
    if (action.type === 'SNACKBAR_NOTIFICATION_REMOVED') {
  
      return {
        ...state,
        notifications: state.notifications.filter(notification => notification.key !== action.key)
      }
    }
  
    return state;
  }
  
  export default reducer;