export const UploadSettings = [
    {
        id: "TITLE",
        title: "Заголовок"
    },
    {
        id: "CUSTOMER",
        title: "Заказчик"
    },
    {
        id: "REQUEST_DATE_END",
        title: "Окончание подачи заявок"
    },
    {
        id: "PRICE",
        title: "Цена контракта"
    },
    {
        id: "PROVISION_CONTRACT",
        title: "Обеспечение контракта"
    },
    {
        id: "PREPAYMENT",
        title: "Аванс"
    },
    {
        id: "PROVISION_REQUEST",
        title: "Обеспечение заявки"
    },
    {
        id: "PROVISION_WARRANTY",
        title: "Обеспечение гарантийных обязательств"
    },
    {
        id: "REGION_CUSTOMER",
        title: "Регион заказчика"
    },
    {
        id: "DELIVERY_ADDRESS",
        title: "Место поставки"
    },
    {
        id: "PURCHASE_URL",
        title: "Ссылка на документацию"
    },
]