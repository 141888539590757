import React from 'react';
import { withRouter } from '../../withRouter';
import styles from '../../css/PurchaseItem/PurchaseItem.module.css'

//MUI
import Grid from '@mui/material/Grid';
import { Typography, Link, Box } from '@mui/material';

class PurchaseItem extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Box className={styles.container}>
          <Grid container spacing={3}>
            <Grid item xs={9}>
              <Grid item className={styles.numberContainer}>
                №
                <Link href={this.props.purchase.sourceUrl}>
                  <Typography component="div">
                     {this.props.purchase.number}
                  </Typography>
                </Link>
              </Grid>

              <Grid item>
                <Typography component="div" className={styles.title}>
                  {this.props.purchase.title}
                </Typography>
              </Grid>

              <Grid item>
                <Typography component="div" className={styles.subtitle}>
                  {this.props.purchase.methodType}
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={3} sx={{display: "flex", flexDirection: "column", alignItems: "flex-end"}}>
              <Grid item>
                <Typography component="div" className={styles.price}>
                  {this.props.purchase.price} ₽
                </Typography>
              </Grid>
              <Grid item>
                <Typography component="div" className={styles.sourcePlatform}>
                  {this.props.purchase.sourcePlatform.name}
                </Typography>
              </Grid>
            </Grid>

          </Grid>
        </Box>
      </React.Fragment>
    )
  }
}

export default withRouter(PurchaseItem);
