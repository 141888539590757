class SettlementService {
  async search(keyword) {
    var baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558';  
    const user = JSON.parse(localStorage.getItem(`oidc.user:${baseIdentityURL}:web_client`));

    const requestOptions = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + (user ? user.access_token : ''),
      },
      body: JSON.stringify({
          keyword: keyword,
          size: 5
      })
    };

    const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_SETTLEMENT_API_URI : 'http://localhost:5554';
    try {
      const response = await fetch(`${baseURL}/api/kladr/search`, requestOptions);
      const settlements = await response.json();
      return settlements;
    } catch (error) {
      console.log('SETTLEMENT SERVICE | SEARCH | ERROR: ', error);
      throw error;
    }
  }
}

export default new SettlementService();