import {styled} from "@mui/material/styles";
import Checkbox, {CheckboxProps} from "@mui/material/Checkbox";
import React from "react";

const BpIconRounded = styled('span')(({theme}) => ({
    borderRadius: 10,
    width: 18,
    height: 18,
    boxSizing: 'content-box',
    border: '0.5px solid #B9B9B9',
    backgroundColor: '#f5f8fa',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:checked ~ &': {
        border: '1px solid #2B65F0'
    },
    'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
    },
}))

const BpCheckedRoundedIcon = styled(BpIconRounded)({
    backgroundColor: '#2B65F0',
    '&::before': {
        display: 'block',
        width: 19,
        height: 19,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#2B65F0',
    },
});

const BpCheckboxCircle = (props: CheckboxProps) => {
    return (
        <Checkbox
            sx={{ '&:hover': { bgcolor: 'transparent' } }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedRoundedIcon />}
            icon={<BpIconRounded />}
            inputProps={{ 'aria-label': 'Checkbox demo' }}
            {...props}
        />
    );
}

export default BpCheckboxCircle
