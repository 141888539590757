import React, {Component} from 'react';
import {withRouter} from '../withRouter';
import PurchaseTagsService from '../services/PurchaseTagsService';
import {DateTime} from 'luxon';
import htmlParse from 'html-react-parser';
import styles from '../css/PurchaseCard/PurchaseCard.module.css'

// MUI
import Card from '@mui/material/Card';
import {connect} from 'react-redux';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {Breadcrumbs, Button, Chip, Divider, Grid, Link, Rating, Stack} from '@mui/material';
import {withSnackbar} from 'notistack';
import {isMobile} from "react-device-detect";
import BpCheckbox from "./CustomCheckbox";

class PurchaseSearchResult extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isMarked: false,
            purchase: this.props.purchase
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.purchase.tags && this.props.purchase.tags !== prevProps.purchase.tags) {
            this.setState({purchase: this.props.purchase});
        }

        if (this.props.tagDialogSelectedPurchase && this.props.tagDialogSelectedPurchase.id === this.state.purchase.id && this.props.tagDialogSelectedPurchase.tags !== this.state.purchase.tags) {
            this.setState({purchase: {...this.props.purchase, tags: this.props.tagDialogSelectedPurchase.tags}})
        }
    }

    getSourcePlatform = (purchase) => {
        if (purchase.sourcePlatform) {
            return purchase.sourcePlatform.name;
        } else {
            return null
        }
    }

    getLotInfo = (purchase) => {
        let value = "";
        if (purchase.lotNumber < 2) {
            return null;
        }
        if (purchase.lotNumber > 1 && purchase.lotNumber < 5) {
            value = purchase.lotNumber + " лота"
        }
        if (purchase.lotNumber > 4) {
            value = purchase.lotNumber + " лот"
        }

        return <>
            <div className={styles.lotChipContainer}>
                <Stack direction="row" spacing={1}>
                    <Chip label={value} alt="Category"/>
                </Stack>
            </div>
        </>
    }

    getLaw = (purchase) => {
        switch (purchase.law) {
            case 1:
                return '44-ФЗ';
            case 2:
                return '223-ФЗ';
            case 3:
                return 'Коммерческие';
            case 4:
                return 'ПП РФ 615';
            default:
                return 'Не определено';
        }
    }

    getFormatDate = (inputDate) => {
        const date = DateTime.fromISO(inputDate);
        return date.toFormat('dd.MM.yyyy HH:mm');
    }

    getLocalFormatDate = (inputDate) => {
        const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const date = DateTime.fromISO(inputDate);
        return date.setZone(localTimezone).toFormat('dd.MM.yyyy HH:mm');
    }

    getDateDiff = (inputDate) => {
        const date = DateTime.fromISO(inputDate);

        const currentDate = DateTime.now();
        let result = "";
        let dateDiff = date.diff(currentDate, ['days', 'hours']).toObject();

        if (dateDiff.days === 1 || (dateDiff.Days === 0 && dateDiff.hours === 1)) {
            result = result.concat("Остался ")
        } else {
            result = result.concat("Осталось ");
        }

        if (dateDiff.days > 0) {
            result = result.concat(dateDiff.days + " д. ");
        }
        if (dateDiff.hours > 1) {
            result = result.concat(parseInt(dateDiff.hours) + " ч. ");
        }
        if (dateDiff.days === 0 && dateDiff.hours < 1 && dateDiff.hours > 0) {
            result = result.concat("менее часа");
        }
        if (dateDiff.hours < 0) {
            result = "";
        }

        return result;
    }

    getStage = (purchase) => {
        switch (purchase.stage) {
            case 1:
                if (!purchase.purchaseExpirationAt) {
                    return <Typography>Подача заявок</Typography>;
                }

                const dateMsk = this.getFormatDate(purchase.purchaseExpirationAt);
                const dateLocal = this.getLocalFormatDate(purchase.purchaseExpirationAt);

                return (
                    <React.Fragment>
                        <Typography>Подача заявок до: </Typography>
                        <Typography sx={{fontWeight: "500"}}>{dateMsk} (мск)</Typography>
                        {dateMsk !== dateLocal ? <Typography>{dateLocal} (местное)</Typography> : null}
                        <Typography>{this.getDateDiff(purchase.purchaseExpirationAt)}</Typography>
                    </React.Fragment>
                );
            case 2:
                return 'Работа комиссии';
            case 3:
                return 'Размещение завершено';
            case 4:
                return 'Размещение отменено';
            case 5:
                return 'Торги не состоялись';
            case 6:
                return 'Торги запланированы';
            case 7:
                return 'На исполнении';
            default:
                return 'Не определено';
        }
    }

    markPurchase = () => {
        this.setState({
            isMarked: true
        });

        this.props.onAddUploadPurchase(this.props.purchase.id, this.props.currentPage);
    }

    unmarkPurchase = () => {
        this.setState({
            isMarked: false
        });

        this.props.onRemoveUploadPurchase(this.props.purchase.id, this.props.currentPage);
    }

    render() {
        return (
            <Card className={styles.cardContainer} key={this.props.key}>
                <CardContent className={styles.cardContent}>
                    <Grid container className={styles.cardGridContainer}>

                        {
                            this.props.purchase.recommended === true &&
                            <Grid item xs={12} sx={{marginBottom: "4px"}}>
                                <Typography variant="overline" color="red" className={styles.recommendationText}>Рекомендовано
                                    Эксперт-Центром!</Typography>
                            </Grid>
                        }

                        <Grid item xs={6} className={styles.cardGridItem}>
                            <Link className={styles.purchaseTitle} rel="noopener noreferrer" target="_blank"
                                  href={"/purchases/" + this.props.purchase.id} underline="hover"
                            >
                                <Typography sx={{
                                    overflow: "hidden",
                                    display: "-webkit-box", WebkitLineClamp: "3", WebkitBoxOrient: "vertical"
                                }}>
                                    {htmlParse(this.props.purchase.title ?? '<объект закупки не указан>')}
                                </Typography>
                            </Link>
                            <Typography className={styles.purchaseLaw} variant="caption" component="div">
                                {[this.getLaw(this.props.purchase), this.props.purchase.methodType, this.props.purchase.number].filter(Boolean).join(', ')}
                            </Typography>
                        </Grid>

                        <Grid item xs={2.5} className={styles.cardGridItem}
                              sx={{display: "flex!important", gap: "6px!important"}}>
                            <Typography variant="subtitle1" component="div" className={styles.purchasePrice}>
                                {this.props.purchase.price ? this.props.purchase.price + ' ₽' : '—'}
                            </Typography>
                            {
                                !!this.props.purchase.prepayment &&
                                <Typography variant="subtitle1" component="div"
                                            className={styles.purchasePriceAdditional}>
                                    Аванс: <span style={{fontWeight: "550"}}>{this.props.purchase.prepayment}%</span>
                                </Typography>
                            }
                            <span className={styles.purchasePriceAdditional}>
                                {this.getSourcePlatform(this.props.purchase)}
                              </span>
                        </Grid>

                        <Grid item xs={2.5} className={styles.cardGridItem}>
                            <Typography variant="subtitle1" component="div" className={styles.cardStagesContainer}>
                                {this.getStage(this.props.purchase)}
                            </Typography>
                        </Grid>
                    </Grid>


                    <Grid container spacing={3} className={styles.cardGridContainer}>
                        <Grid>
                            <Typography variant="caption" sx={{pt: 3}} className={styles.purchaseCustomer}
                                        component="div">{this.props.purchase.customerOrganizationName}</Typography>
                            <Rating value={parseFloat(this.props.purchase.customerRating)} precision={0.1} readOnly/>
                        </Grid>
                    </Grid>

                    {
                        this.props.purchase.documentsInText.length > 0 &&
                        <Grid style={{marginTop: "6px"}}>
                            <Grid>
                                <Typography variant="subtitle2">Найдено в документах:</Typography>
                            </Grid>
                            {this.props.purchase.documentsInText.map((doc, key) =>
                                <Grid key={key}>
                                    <Divider sx={{mb: 2}}/>
                                    <Breadcrumbs aria-label="breadcrumb">
                                        {doc.parentFiles.map((parentFile, key) =>
                                            <Typography key={key}>{parentFile}</Typography>
                                        )}
                                        <Typography>{doc.fileName}</Typography>
                                    </Breadcrumbs>
                                    {doc.highlightedContent.map((highlightedText, key) =>
                                        <Typography className="highlighted" variant='subtitle2' key={key}>
                                            {htmlParse(`...${highlightedText}...`)}
                                        </Typography>
                                    )}
                                </Grid>
                            )}
                        </Grid>
                    }

                    {
                        this.props.purchase.highlightedLotNames && this.props.purchase.highlightedLotNames.length > 0 &&
                        <Grid style={{marginTop: "6px"}}>
                            <Grid>
                                <Typography variant="subtitle2">Найдено в лотах:</Typography>
                            </Grid>
                            {this.props.purchase.highlightedLotNames.map((doc, key) =>
                                <Grid key={key}>
                                    <Typography className="highlighted" variant="subtitle2" key={key}>
                                        {htmlParse(`... ${doc} ...`)}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    }

                    {
                        this.props.purchase.highlightedOkpdNames && this.props.purchase.highlightedOkpdNames.length > 0 &&
                        <Grid style={{marginTop: "6px"}}>
                            <Grid>
                                <Typography variant="subtitle2">Найдено по наименованию ОКПД2:</Typography>
                            </Grid>
                            {this.props.purchase.highlightedOkpdNames.map((doc, key) =>
                                <Grid key={key}>
                                    <Typography className="highlighted" variant="subtitle2" key={key}>
                                        {htmlParse(`... ${doc} ...`)}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    }

                    {
                        this.props.purchase.highlightedOkvedNames && this.props.purchase.highlightedOkvedNames.length > 0 &&
                        <Grid style={{marginTop: "6px"}}>
                            <Grid>
                                <Typography variant="subtitle2">Найдено по наименованию ОКВЭД2:</Typography>
                            </Grid>
                            {this.props.purchase.highlightedOkvedNames.map((doc, key) =>
                                <Grid key={key}>
                                    <Typography className="highlighted" variant="subtitle2" key={key}>
                                        {htmlParse(`... ${doc} ...`)}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    }
                </CardContent>

                {this.getLotInfo(this.props.purchase)}

                {/*<CardContent>*/}
                {/*      <Typography>*/}
                {/*        {this.props.purchase.region ?? (<RegionNameByKladrCode kladrCode={this.props.purchase.kladrId} />)}*/}
                {/*      </Typography>*/}
                {/*</CardContent>*/}


                <CardContent className={styles.cardContentButton}>
                    {
                        this.state.purchase.tags &&
                        <Stack direction="row" spacing={1} sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                            alignItems: 'center',
                            flexWrap: "wrap"
                        }}>
                            {this.state.purchase.tags.map((tag, key) =>
                                <Chip icon={<img src="/star_icon.svg" alt="Star"
                                                 style={{width: "14px", height: "14px", marginLeft: "10px"}}/>}
                                      label={tag.name} key={key} sx={{marginLeft: "0!important"}}/>
                            )}
                        </Stack>
                    }
                    <Stack direction="row" justifyContent='flex-end' spacing={1}>
                        {
                            this.props.isMarked ?
                                <Button
                                    sx={{
                                        borderRadius: '10px',
                                        fontFamily: 'SF Pro Text, serif',
                                        fontSize: "15px",
                                        textTransform: "unset!important",
                                        letterSpacing: "-0.41px",
                                        lineHeight: "20px!important",
                                        color: "#FFFFFF",
                                        fontWeight: "500",
                                        minWidth: isMobile ? 'unset!important' : '64px',
                                        padding: isMobile ? '1px' : '10px 18px',
                                        border: isMobile ? '1px solid #B0B0B6' : '',
                                        backgroundColor: isMobile ? "#FFFFFF" : "#2B65F0",
                                        '&:hover': {
                                            backgroundColor: isMobile ? "#FFFFFF" : "#2B65F0",
                                        }
                                    }}
                                    variant={isMobile ? 'outlined' : "contained"}
                                    onClick={() => this.unmarkPurchase()}
                                >
                                    {isMobile ?
                                        <BpCheckbox checked={true} unselectable='true'/>
                                        :
                                        'Отмечено'}
                                </Button> :
                                <Button
                                    sx={{
                                        borderRadius: '10px',
                                        fontFamily: 'SF Pro Text, serif',
                                        fontSize: "15px",
                                        textTransform: "unset!important",
                                        letterSpacing: "-0.41px",
                                        lineHeight: "20px!important",
                                        color: "#2B65F0",
                                        fontWeight: "400",
                                        minWidth: isMobile ? 'unset!important' : '64px',
                                        padding: isMobile ? '1px' : '10px 18px',
                                        border: isMobile ? '1px solid #B0B0B6' : '',
                                    }}
                                    variant={isMobile ? 'outlined' : "text"}
                                    onClick={() => this.markPurchase()}
                                >
                                    {isMobile ?
                                        <BpCheckbox checked={false} unselectable='true'/>
                                        :
                                        'Отметить'}
                                </Button>
                        }
                        <Button
                            sx={{
                                borderRadius: '10px',
                                fontFamily: 'SF Pro Text, serif',
                                fontSize: "15px",
                                textTransform: "unset!important",
                                letterSpacing: "-0.41px",
                                lineHeight: "20px!important",
                                color: "#2B65F0",
                                fontWeight: "400",
                                minWidth: isMobile ? 'unset!important' : '64px',
                                padding: isMobile ? '9px' : '10px 18px',
                                border: isMobile ? '1px solid #B0B0B6' : '',
                                '& .MuiButton-startIcon': {
                                    marginRight: isMobile ? "0!important" : "8px",
                                    marginLeft: isMobile ? "0!important" : "-4px"
                                }
                            }}
                            startIcon={<img src="/star_icon.svg" alt="Close"/>}
                            variant={isMobile ? 'outlined' : "text"}
                            onClick={() => this.props.onPurchaseTagAddCalled(this.props.purchase)}
                        >
                            {isMobile ? '' : 'Избранное'}
                        </Button>
                    </Stack>
                </CardContent>
            </Card>
        );
    }

}

function mapStateToProps(state, ownProps) {
    if (state.selectTagDialogState.selectedPurchase.id === ownProps.purchase.id) {
        return {
            tagDialogSelectedPurchase: state.selectTagDialogState.selectedPurchase,
            currentPage: state.pagesState.currentPage,
        };
    } else {
        return {
            currentPage: state.pagesState.currentPage,
        };
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onPurchaseTagAddCalled: (purchase) => {
            dispatch({type: 'OPEN_DIALOG'});
            PurchaseTagsService.getTags().then(result => {
                const action = {
                    type: 'OPEN_TAG_DIALOG',
                    selectedPurchase: purchase,
                    tags: result
                };
                dispatch(action);
            })
                .catch(() => {
                    const notificationAction = {
                        type: 'SNACKBAR_NOTIFICATION_SENT',
                        notification: {
                            message: 'Не удалось получить папки избранного',
                            variant: 'error'
                        }
                    };
                    dispatch(notificationAction);
                });
        },
        onAddUploadPurchase: (uploadPurchaseId, currentPage) => {
            const action = {
                type: 'ADD_UPLOAD_PURCHASE',
                uploadPurchaseId: uploadPurchaseId,
                currentPage: currentPage
            }
            dispatch(action);
        },
        onRemoveUploadPurchase: (uploadPurchaseId, currentPage) => {
            const action = {
                type: 'REMOVE_UPLOAD_PURCHASE',
                uploadPurchaseId: uploadPurchaseId,
                currentPage: currentPage
            }
            dispatch(action);
        },
    }
}

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(withRouter(PurchaseSearchResult)));
