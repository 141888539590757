class TemplateService {
  async getAll() {
    const baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558';
    const user = JSON.parse(localStorage.getItem(`oidc.user:${baseIdentityURL}:web_client`));

    const requestOptions = {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + (user ? user.access_token : ''),
      }
    };

    const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_API_URI : 'http://localhost:5559';
    const response = await fetch(`${baseURL}/api/purchases/templates`, requestOptions);
    const templates = await response.json();
    return templates;
  }    

  async getById(id) {
    var baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558';  
    const user = JSON.parse(localStorage.getItem(`oidc.user:${baseIdentityURL}:web_client`));

    const requestOptions = {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + (user ? user.access_token : ''),
      }
    };

    const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_API_URI : 'http://localhost:5559';
    const response = await fetch(`${baseURL}/api/purchases/templates/${id}`, requestOptions);
    const template = await response.json();
    return template;
  }

  async add(newTemplate) {
    var baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558';   
    const user = JSON.parse(localStorage.getItem(`oidc.user:${baseIdentityURL}:web_client`));

    const requestOptions = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + (user ? user.access_token : ''),
      },
      body: JSON.stringify(newTemplate)
    };

    const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_API_URI : 'http://localhost:5559';
    const response = await fetch(`${baseURL}/api/purchases/templates`, requestOptions);
    const template = await response.json();
    if (template.status === 400) {
      throw template;
    }
    else return template;
  }

  async update(id, changeTemplate) {
    var baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558';  
    const user = JSON.parse(localStorage.getItem(`oidc.user:${baseIdentityURL}:web_client`));

    const requestOptions = {
      method: 'PUT',
      headers: { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + (user ? user.access_token : ''),
      },
      body: JSON.stringify(changeTemplate)
    };

    const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_API_URI : 'http://localhost:5559';
    const response = await fetch(`${baseURL}/api/purchases/templates/${id}`, requestOptions);
    const template = await response.json();
    if (template.status === 400) {
      throw template;
    }
    else return template;
  }

  async remove(id) {
    var baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558';
    const user = JSON.parse(localStorage.getItem(`oidc.user:${baseIdentityURL}:web_client`));

    const requestOptions = {
      method: 'DELETE',
      headers: { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + (user ? user.access_token : ''),
      }
    };

    const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_API_URI : 'http://localhost:5559';
    const response = await fetch(`${baseURL}/api/purchases/templates/${id}`, requestOptions);
    
  }    
}

export default new TemplateService();
