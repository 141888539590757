import { combineReducers } from 'redux';
import tagsReducer from './reducers/tagsReducer';
import purchasesReducer from './reducers/purchasesReducer';
import organizationsReducer from './reducers/organizationsReducer';
import templatesReducer from './reducers/templatesReducer';
import selectTagDialogReducer from './reducers/selectTagDialogReducer';
import pagesReducer from './reducers/pagesReducer';
import okpdReducer from './reducers/okpdReducer';
import mapReducer from './reducers/mapReducer';
import industriesReducer from './reducers/industriesReducer';
import markedPurchasesReducer from './reducers/markedPurchasesReducer';
import markedTemplatesReducer from './reducers/markedTemplatesReducer';
import purchaseTransportLogsReducer from './reducers/purchaseTransportLogsReducer';
import profileReducer from './reducers/profileReducer';
import notificationsReducer from './reducers/notificationsReducer';
import customersReducer from './reducers/customersReducer';

const rootReducer = combineReducers({
    tagsState: tagsReducer,
    purchasesState: purchasesReducer,
    organizationsState: organizationsReducer,
    templatesState: templatesReducer,
    selectTagDialogState: selectTagDialogReducer,
    pagesState: pagesReducer,
    okpdState: okpdReducer,
    mapState: mapReducer,
    industriesState: industriesReducer,
    markedPurchasesState: markedPurchasesReducer,
    markedTemplatesState: markedTemplatesReducer,
    purchaseTransportLogsState: purchaseTransportLogsReducer,
    profileState: profileReducer,
    notificationsState: notificationsReducer,
    customersState: customersReducer
});

export default rootReducer;