class PurchaseTagsService {
  async getPurchaseTags(purchaseIds) {
    if (purchaseIds)
    {
      var baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558';      
      const user = JSON.parse(localStorage.getItem(`oidc.user:${baseIdentityURL}:web_client`));

      const requestOptions = {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json', 
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + (user ? user.access_token : ''),
        },
        body: JSON.stringify(purchaseIds)
      };

      const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_TAGS_API_URI : 'http://localhost:5563';
      const response = await fetch(`${baseURL}/api/tags/getByPurchases`, requestOptions);
      if (response.status === 200)
      {
        const tags = await response.json();
        return tags;
      }
    }
    return null;
  }

  async getTags() {
    var baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558';   
    const user = JSON.parse(localStorage.getItem(`oidc.user:${baseIdentityURL}:web_client`));

    const requestOptions = {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + (user ? user.access_token : ''),
      }
    };

    const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_TAGS_API_URI : 'http://localhost:5563';
    const response = await fetch(`${baseURL}/api/tags`, requestOptions);
    if (response.status === 200)
    {
      const tags = await response.json();
      return tags;
    }
  }

  async getTag(tagId) {
    var baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558'; 
    const user = JSON.parse(localStorage.getItem(`oidc.user:${baseIdentityURL}:web_client`));

    const requestOptions = {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + (user ? user.access_token : ''),
      }
    };

    const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_TAGS_API_URI : 'http://localhost:5563';
    const response = await fetch(`${baseURL}/api/tags/${tagId}`, requestOptions);
    if (response.status === 200)
    {
      const tags = await response.json();
      return tags;
    }
  }

  async addTag(tag) {
    var baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558';  
    const user = JSON.parse(localStorage.getItem(`oidc.user:${baseIdentityURL}:web_client`));

    const requestOptions = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + (user ? user.access_token : ''),
      },
      body: JSON.stringify(tag)
    };

    const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_TAGS_API_URI : 'http://localhost:5563';
    const response = await fetch(`${baseURL}/api/tags`, requestOptions);

    return await response.json();
  }

  async updateTag(tagId, tag) {
    var baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558';  
    const user = JSON.parse(localStorage.getItem(`oidc.user:${baseIdentityURL}:web_client`));

    const requestOptions = {
      method: 'PUT',
      headers: { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + (user ? user.access_token : ''),
      },
      body: JSON.stringify(tag)
    };

    const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_TAGS_API_URI : 'http://localhost:5563';
    const response = await fetch(`${baseURL}/api/tags/${tagId}`, requestOptions);

    if (response.status >= 400) {
      return await response.json();
    }
  }

  async removeTag(tagId) {
    var baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558';   
    const user = JSON.parse(localStorage.getItem(`oidc.user:${baseIdentityURL}:web_client`));

    const requestOptions = {
      method: 'DELETE',
      headers: { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + (user ? user.access_token : ''),
      }
    };

    const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_TAGS_API_URI : 'http://localhost:5563';
    const response = await fetch(`${baseURL}/api/tags/${tagId}`, requestOptions);
  }

  async removePurchaseTag(purchaseTag) {
    var baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558';  
    const user = JSON.parse(localStorage.getItem(`oidc.user:${baseIdentityURL}:web_client`));

    const requestOptions = {
      method: 'DELETE',
      headers: { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + (user ? user.access_token : ''),
      },
      body: JSON.stringify(purchaseTag)
    };

    const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_TAGS_API_URI : 'http://localhost:5563';
    const response = await fetch(`${baseURL}/api/tags/purchases`, requestOptions);
  }

  async setPurchaseTag(purchase) {
    var baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558';  
    const user = JSON.parse(localStorage.getItem(`oidc.user:${baseIdentityURL}:web_client`));

    const requestOptions = {
      method: 'PUT',
      headers: { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + (user ? user.access_token : '')
      },
      body: JSON.stringify(purchase)
    };

    const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_TAGS_API_URI : 'http://localhost:5563';
    const response = await fetch(`${baseURL}/api/tags/set-purchase-tag`, requestOptions);
  }

  async getPurchasesForTag(tagId, pageSize, pageNumber) {
    var baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558';   
    const user = JSON.parse(localStorage.getItem(`oidc.user:${baseIdentityURL}:web_client`));

    const requestOptions = {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + (user ? user.access_token : '')
      }//,
      //body: JSON.stringify([tag])
    };

    const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_TAGS_API_URI : 'http://localhost:5563';
    const response = await fetch(`${baseURL}/api/tags/purchases-with-pagination/${tagId}?pageSize=${pageSize}&pageNumber=${pageNumber}`, requestOptions);

    if (response.status === 200)
    {
      const purchases = await response.json();
      return purchases;
    }
  }
}

export default new PurchaseTagsService();