const initialState = {
	uploadFields: [],
	uploadPurchaseIds: []
}
  
const reducer = (state = initialState, action) => {
	if (action.type === 'PURCHASES_FOUND' && action.currentPage === 'PURCHASES') {
		return {
      ...state,
      uploadPurchaseIds: action.isNewSearch ? [] : state.uploadPurchaseIds
    };
	}

	if (action.type === 'CLEAR_UPLOAD_PURCHASES' && action.currentPage === 'PURCHASES') {
		return {
			...state,
			uploadPurchaseIds: []
		}    
	}

	if (action.type === 'ADD_UPLOAD_FIELD' && action.currentPage === 'PURCHASES') {
		var value = action.uploadField;

		const uploadFields = [...state.uploadFields, value];

		return {
			...state,
			uploadFields: uploadFields
		}
	}		

  if (action.type === 'REMOVE_UPLOAD_FIELD' && action.currentPage === 'PURCHASES') {
    let value = action.uploadField;
    let array = [...state.uploadFields];
    let index = array.indexOf(value)
    if (index !== -1) {
      array.splice(index, 1);

      return {
        ...state,
        uploadFields: array
      }
    }
  };	

  if (action.type === 'UPLOAD_SETTINGS' && action.currentPage === 'PURCHASES') {
    let uploadFields = action.settings;
    return {
      ...state,
      uploadFields: uploadFields
    }      
  }	

  if (action.type === 'ADD_UPLOAD_PURCHASE' && action.currentPage === 'PURCHASES') {
    let value = action.uploadPurchaseId;

    const uploadPurchaseIds = [...state.uploadPurchaseIds, value];

    return {
      ...state,
      uploadPurchaseIds: uploadPurchaseIds
    }
  }	

  if (action.type === 'REMOVE_UPLOAD_PURCHASE' && action.currentPage === 'PURCHASES') {
    let value = action.uploadPurchaseId;
    let array = [...state.uploadPurchaseIds];
    let index = array.indexOf(value)
    if (index !== -1) {
      array.splice(index, 1);

      return {
        ...state,
        uploadPurchaseIds: array
      }
    }
  };

  if (action.type === 'RESET_SEARCH_PURCHASES' && action.currentPage === 'PURCHASES') {
    return {
      ...state,
      total: 0,
      uploadPurchaseIds: []
    }
  }

  if (action.type === 'ADD_MANY_UPLOAD_FIELDS' && action.currentPage === 'PURCHASES') {
    let set = new Set(state.uploadPurchaseIds);
    action.uploadPurchaseIds.forEach(item => set.add(item));

    return {
      ...state,
      uploadPurchaseIds: [...set]
    }
  }

  if (action.type === 'REMOVE_MANY_UPLOAD_FIELDS' && action.currentPage === 'PURCHASES') {
    const updatedPurchaseIds = state.uploadPurchaseIds.filter(item => !action.uploadPurchaseIds.includes(item));
      return {
        ...state,
        uploadPurchaseIds: updatedPurchaseIds
      }
  }

	return state;
}

export default reducer;