class CustomerRatingService {
  async getCustomerRating(customerOrgId) {
    if (customerOrgId) {
      var baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558';    
      const user = JSON.parse(localStorage.getItem(`oidc.user:${baseIdentityURL}:web_client`));

      const requestOptions = {
        method: 'GET',
        headers: { 
          'Content-Type': 'application/json', 
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + (user ? user.access_token : ''),
        }
      };

      const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_ORGANIZATIONS_API_URI : 'http://localhost:5560';
      const response = await fetch(`${baseURL}/api/review/rating/${customerOrgId}`, requestOptions);
      if (response.status === 200)
      {
        const customerRating = await response.json();
        return customerRating;
      }
    }
    return null;
  }

  async getCustomerRatings(customerOrgIds) {
    if (customerOrgIds)
    {
      var baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558';

      const user = JSON.parse(localStorage.getItem(`oidc.user:${baseIdentityURL}:web_client`));

      const requestOptions = {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json', 
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + (user ? user.access_token : ''),
        },
        body: JSON.stringify(customerOrgIds)
      };

      const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_ORGANIZATIONS_API_URI : 'http://localhost:5560';
      const response = await fetch(`${baseURL}/api/review/ratings`, requestOptions);
      if (response.status === 200)
      {
        const customerRating = await response.json();
        return customerRating;
      }
    }
    return null;
  }

  async SendReview(reviewOptions) {
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify(reviewOptions)
    };

    const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_CUSTOMER_RATING_API_URI : 'http://localhost:5560';
    var response = await fetch(`${baseURL}/api/review`, requestOptions);   
    if (response.status === 200)
    {
      return true;
    }
    return false;
  }

  async GetReviewRequest(requestId) {
    const requestOptions = {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }
    };

    const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_CUSTOMER_RATING_API_URI : 'http://localhost:5560';
    const encodedRequestId = encodeURIComponent(requestId);
    const response = await fetch(`${baseURL}/api/review/${encodedRequestId}`, requestOptions);
    if (response.status === 200)
    {
      const reviewRequest = await response.json();
      return reviewRequest;
    }
    return null;
  }

  async GetReviews(options) {
    var baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558';
    const user = JSON.parse(localStorage.getItem(`oidc.user:${baseIdentityURL}:web_client`));

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + (user ? user.access_token : '')
      },
      body: JSON.stringify(options)
    };

    const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_CUSTOMER_RATING_API_URI : 'http://localhost:5560';
    const response = await fetch(`${baseURL}/api/review/page`, requestOptions);
    if (response.status === 200)
    {
      const reviews = await response.json();
      return reviews;
    }
  }
}

export default new CustomerRatingService();