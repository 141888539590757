import React from 'react';
import {connect} from 'react-redux';
import PurchaseService from '../services/PurchaseService';
import PurchaseTagsService from '../services/PurchaseTagsService';
import SelectTagDialog from '../components/SelectTagDialog';
import {DateTime} from 'luxon';
import styles from '../css/Tags/Tags.module.css'

//MUI
import {
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle,
    Grid,
    IconButton,
    Link,
    Pagination,
    Stack, SwipeableDrawer,
    TextField,
    Typography
} from '@mui/material';
import DialogContent from "@mui/material/DialogContent";
import {styled, ThemeProvider} from "@mui/material/styles";
import {isMobile} from "react-device-detect";
import {grey} from "@mui/material/colors";

const dialogInputStyle = {
    width: "500px",
    margin: "5px"
};

const Puller = styled('div')(({theme}) => ({
    width: 30,
    height: 6,
    backgroundColor: grey[300],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)'
}));

class Tags extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            addTagName: ''
        };
    }

    componentDidMount() {
        this.props.onUpdateTags();
    }

    componentDidUpdate(prevProps) {
        //if changed tags in selectedPurchase and dont changed it's id - then we must rerender page
        if (prevProps.tagDialogSelectedPurchase.tags !== this.props.tagDialogSelectedPurchase.tags
            && prevProps.tagDialogSelectedPurchase.id === this.props.tagDialogSelectedPurchase.id) {
            this.props.onGetPurchases(this.props.selectedTagId, this.props.pageSize, this.props.pageNumber);
        }
    }

    getSourcePlatform = (purchase) => {
        if (purchase.sourcePlatform) {
            return purchase.sourcePlatform.name;
        } else {
            return null
        }
    }

    getLaw = (purchase) => {
        switch (purchase.law) {
            case 1:
                return '44-ФЗ';
            case 2:
                return '223-ФЗ';
            case 3:
                return 'Коммерческие';
            default:
                return 'Не определено';
        }
    }

    getFormatDate = (inputDate) => {
        const date = DateTime.fromISO(inputDate);
        const formattedDate = date.toFormat('dd.MM.yyyy HH:mm');

        return formattedDate;
    }

    getStage = (purchase) => {
        switch (purchase.stage) {
            case 1:
                return 'Подача заявок ' + this.getFormatDate(purchase.purchaseExpirationAt);
            case 2:
                return 'Работа комиссии';
            case 3:
                return 'Размещение завершено';
            case 4:
                return 'Размещение отменено';
            case 5:
                return 'Торги не состоялись';
            case 6:
                return 'Торги запланированы';
            case 7:
                return 'На исполнении';
            default:
                return 'Не определено';
        }
    }

    render() {
        return (
            <React.Fragment>
                <SelectTagDialog/>

                {
                    isMobile ?
                        <SwipeableDrawer
                            anchor='bottom'
                            open={this.props.editTagDialogOpened}
                            onClose={this.props.onCancelEditDialog}
                            PaperProps={{
                                sx: {
                                    width: "100%",
                                    borderRadius: "16px 16px 0 0",
                                    maxHeight: "80%",
                                    height: "50%"
                                }
                            }}
                            scroll={"paper"}
                        >
                            <Puller/>
                            <DialogTitle
                                sx={{
                                    m: 0,
                                    padding: '32px 16px 12px',
                                    fontSize: "20px!important",
                                    fontWeight: "600",
                                    fontFamily: "SF Pro Text, sans-serif",
                                    letterSpacing: "-0.41px"
                                }}
                                id="customized-dialog-title">
                                Добавить папку
                            </DialogTitle>

                            <DialogContent sx={{borderBottom: "0.5px solid #E8E8E8", paddingTop: "12px", paddingLeft: "16px", paddingRight: "16px"}}>
                                <Box>
                                    <ThemeProvider theme={{
                                        components: {
                                            MuiOutlinedInput: {
                                                styleOverrides: {
                                                    root: {
                                                        padding: "9px 12px",
                                                        borderRadius: "4px",
                                                        fontSize: "16px",
                                                        fontFamily: "SF Pro Text, serif",
                                                        letterSpacing: "-0.41px",
                                                        '& label.Mui-focused': {
                                                            color: 'var(--TextField-brandBorderFocusedColor)',
                                                        },
                                                    },
                                                    input: {
                                                        padding: 0
                                                    }
                                                }
                                            }
                                        }
                                    }}>
                                        <TextField
                                            fullWidth
                                            placeholder="Введите название папки"
                                            defaultValue={this.props.editTag.name}
                                            onChange={(event) => {
                                                this.props.onEditTagName(event.target.value)
                                            }}
                                        />
                                    </ThemeProvider>
                                    {this.props.editTagDialogErrors.map((err, key) => <Grid item xs={12}
                                                                                            sx={{color: 'red', m: 1}}
                                                                                            key={key}>*{err}</Grid>)}
                                </Box>
                            </DialogContent>

                            <DialogActions sx={{justifyContent: 'flex-start', pt: 2, pb: 3, paddingLeft: '16px'}}>
                                <Button
                                    sx={{
                                        borderRadius: '10px',
                                        backgroundColor: '#2B65F0',
                                        padding: '10px 18px',
                                        width: '120px',
                                        fontFamily: 'SF Pro Text, serif',
                                        fontSize: "16px",
                                        textTransform: "unset!important",
                                        letterSpacing: "-0.41px",
                                        lineHeight: "unset!important",
                                        fontWeight: "500",
                                        '&:hover': {
                                            backgroundColor: "#3060DC!important"
                                        }
                                    }}
                                    variant="contained"
                                    onClick={() => this.props.onSaveEditDialog(this.props.editTag, this.props.editTagId)}>Сохранить</Button>
                                <Button
                                    sx={{
                                        borderRadius: '10px',
                                        padding: '10px 18px',
                                        fontFamily: 'SF Pro Text, serif',
                                        fontSize: "16px",
                                        textTransform: "unset!important",
                                        letterSpacing: "-0.41px",
                                        lineHeight: "unset!important",
                                        color: "#000000",
                                        fontWeight: "400"
                                    }}
                                    startIcon={<img src="/close_icon.svg" alt="Close"/>} variant="text"
                                    onClick={() => this.props.onCancelEditDialog()}>Отмена</Button>
                            </DialogActions>
                        </SwipeableDrawer> :
                        <Dialog
                            open={this.props.editTagDialogOpened}
                            onClose={this.props.onCancelEditDialog}
                            PaperProps={{
                                sx: {
                                    maxWidth: "600px",
                                    width: "600px",
                                    borderRadius: "16px",
                                    maxHeight: "80%"
                                }
                            }}
                            scroll={"paper"}
                        >
                            <DialogTitle sx={{
                                m: 0,
                                p: 3,
                                fontSize: "20px!important",
                                fontWeight: "600",
                                fontFamily: "SF Pro Text, serif",
                                letterSpacing: "-0.41px"
                            }} id="customized-dialog-title">
                                Добавить папку
                            </DialogTitle>
                            <IconButton
                                aria-label="close"
                                onClick={this.props.onCancelEditDialog}
                                sx={(theme) => ({
                                    position: 'absolute',
                                    right: 16,
                                    top: 22,
                                    color: theme.palette.grey[500],
                                })}
                            >
                                <img src="/close_icon.svg" alt="Close"/>
                            </IconButton>

                            <DialogContent sx={{borderBottom: "0.5px solid #E8E8E8", paddingTop: "0"}}>
                                <Grid>
                                    <Grid>
                                        <ThemeProvider theme={{
                                            components: {
                                                MuiOutlinedInput: {
                                                    styleOverrides: {
                                                        root: {
                                                            padding: "12px 16px",
                                                            borderRadius: "4px",
                                                            fontSize: "15px",
                                                            maxWidth: "600px",
                                                            fontFamily: "SF Pro Text, serif",
                                                            letterSpacing: "-0.41px",
                                                            '&:hover fieldset': {
                                                                borderColor: "#B2BAC2"
                                                            },
                                                            '&:focus-visible': {
                                                                borderColor: "#1365E3!important",
                                                            },
                                                            '&:focus-visible &:hover fieldset': {
                                                                borderColor: "#1365E3!important",
                                                            },
                                                        },
                                                        input: {
                                                            padding: "0",
                                                        }
                                                    }
                                                }
                                            }
                                        }}>
                                            <TextField
                                                defaultValue={this.props.editTag.name}
                                                onChange={(event) => {
                                                    this.props.onEditTagName(event.target.value)
                                                }}
                                                sx={dialogInputStyle}
                                            />
                                        </ThemeProvider>
                                    </Grid>
                                    {this.props.editTagDialogErrors.map((err, key) => <Grid item xs={12}
                                                                                            sx={{color: 'red', m: 1}}
                                                                                            key={key}>*{err}</Grid>)}
                                </Grid>
                            </DialogContent>

                            <DialogActions
                                sx={{justifyContent: 'flex-start', pt: 2, pb: 2, paddingLeft: '24px', paddingRight: '24px'}}>
                                <Button
                                    sx={{
                                        borderRadius: '10px',
                                        backgroundColor: '#2B65F0',
                                        padding: '10px 18px',
                                        width: '120px',
                                        fontFamily: 'SF Pro Text, serif',
                                        fontSize: "16px",
                                        textTransform: "unset!important",
                                        letterSpacing: "-0.41px",
                                        lineHeight: "unset!important",
                                        fontWeight: "500",
                                        '&:hover': {
                                            backgroundColor: "#3060DC!important"
                                        }
                                    }}
                                    variant="contained"
                                    onClick={() => this.props.onSaveEditDialog(this.props.editTag, this.props.editTagId)}>Сохранить</Button>
                                <Button
                                    sx={{
                                        borderRadius: '10px',
                                        padding: '10px 18px',
                                        fontFamily: 'SF Pro Text, serif',
                                        fontSize: "16px",
                                        textTransform: "unset!important",
                                        letterSpacing: "-0.41px",
                                        lineHeight: "unset!important",
                                        color: "#000000",
                                        fontWeight: "400"
                                    }}
                                    startIcon={<img src="/close_icon.svg" alt="Close"/>} variant="text"
                                    onClick={() => this.props.onCancelEditDialog()}>Отмена</Button>
                            </DialogActions>
                        </Dialog>
                }

                <Box
                    className={styles.tagsButtonContainer}
                >
                    <Box className={styles.tagsContainerInner}>
                        {
                            this.props.isTagsLoading ?
                                <CircularProgress/> :
                                this.props.tags ? this.props.tags.map((tag, key) => {
                                    let variant = tag.id === this.props.selectedTagId ? "contained" : "outlined";
                                    return <Box key={key}>
                                        <Button className={styles.actionButton} variant={variant}
                                                onClick={() => this.props.onGetPurchases(tag.id, this.props.pageSize, 1)}>{tag.name}</Button>
                                    </Box>
                                }) : null
                        }

                    </Box>

                    <Box className={styles.tagsActionContainer}>
                        {isMobile ? 'Папки' : null}
                        <div>
                            {
                                this.props.selectedTagId
                                    ?
                                    <Box style={{display: "flex", flexDirection: "row"}}>
                                        <Box sx={{p: 0.5}}>
                                            <Button
                                                className={styles.actionButton} variant="outlined"
                                                onClick={() => this.props.onOpenEditTagDialog(this.props.selectedTagId)}>
                                                <img src="/edit_icon.svg" alt="edit"/>
                                            </Button>
                                        </Box>

                                        <Box sx={{p: 0.5}}>
                                            <Button
                                                className={styles.actionButton} variant="outlined" color="error"
                                                onClick={() => this.props.onRemovingTag(this.props.selectedTagId)}>
                                                <img src="/trash_icon.svg" alt="trash"/>
                                            </Button>
                                        </Box>
                                    </Box>
                                    :
                                    null
                            }
                            <Box sx={{p: 0.5}}>
                                <Button className={styles.actionButton}
                                        sx={{
                                            backgroundColor: "#2B65F0",
                                            fontWeight: "500!important",
                                            width: isMobile ? 'unset' : '120px'
                                        }}
                                        variant="contained" onClick={() => this.props.onOpenEditTagDialog()}>+</Button>
                            </Box>
                        </div>
                    </Box>
                </Box>

                {/*Loader*/}
                {this.props.isTagPurchasesLoading && <Box className={styles.loaderContainer} sx={{display: 'flex'}}><CircularProgress/></Box>}

                {/*CardsContainer*/}
                <Box className={styles.cardsContainer}>
                    {
                        this.props.purchases.length > 0 &&
                        this.props.purchases.map((purchase, key) =>
                            <Card variant="outlined" className={styles.cardContainer} key={key}>
                                <CardContent className={styles.cardContent}>
                                    <Grid container className={styles.cardGridContainer}>
                                        <Grid item xs={6} className={styles.cardGridItem}>
                                            <Link className={styles.purchaseTitle} rel="noopener noreferrer"
                                                  target="_blank" href={"/purchases/" + purchase.id} underline="hover"
                                                  onChange={() => this.props.navigate(`/purchases/${purchase.id}`)}>
                                                {purchase.title}
                                            </Link>
                                            <Typography className={styles.purchaseLaw} variant="caption"
                                                        color="text.secondary" component="div">
                                                {[this.getLaw(purchase), purchase.methodType, purchase.number].filter(Boolean).join(', ')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2.5} className={styles.cardGridItem}>
                                            <Typography variant="subtitle1" component="div"
                                                        className={styles.purchasePrice}>
                                                {purchase.price ? purchase.price + ' ₽' : '—'}
                                            </Typography>
                                            <span className={styles.purchasePriceAdditional}>
                            {this.getSourcePlatform(purchase)}
                          </span>
                                        </Grid>
                                        <Grid item xs={2.5} className={styles.cardGridItem}>
                                            <Typography variant="subtitle1" component="div"
                                                        className={styles.cardStagesContainer}>
                                                {this.getStage(purchase)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardContent className={styles.cardContentButton}>
                                    <Stack direction="row" justifyContent='flex-end' sx={{gap: "12px"}}>
                                        <Button
                                            sx={{
                                                borderRadius: '10px',
                                                padding: '10px 18px',
                                                fontFamily: 'SF Pro Text, serif',
                                                fontSize: "15px",
                                                textTransform: "unset!important",
                                                letterSpacing: "-0.41px",
                                                lineHeight: "20px!important",
                                                color: "#2B65F0",
                                                fontWeight: "400"
                                            }}
                                            variant={isMobile ? 'outlined' : "text"}
                                            size="small"
                                            onClick={() => {
                                                this.props.onPurchaseTagChangeCalled(purchase, this.props.selectedTagId);
                                            }}
                                        >
                                            Сменить папку
                                        </Button>

                                        <Button
                                            sx={{
                                                borderRadius: '10px',
                                                padding: '10px 18px',
                                                fontFamily: 'SF Pro Text, serif',
                                                fontSize: "15px",
                                                textTransform: "unset!important",
                                                letterSpacing: "-0.41px",
                                                lineHeight: "20px!important",
                                                fontWeight: "400",
                                                color: isMobile ? '#d32f2f' : '#000000'
                                            }}
                                            variant="text"
                                            size="small"
                                            color="error"
                                            startIcon={isMobile ? <img src="/trash_icon.svg" alt="trash"/> : null}
                                            onClick={() => {
                                                this.props.onRemovingPurchaseTag({
                                                    purchaseId: purchase.id,
                                                    tagId: this.props.selectedTagId
                                                })
                                                    .then(() => {
                                                        this.props.onGetPurchases(this.props.selectedTagId, this.props.pageSize, this.props.pageNumber);
                                                    });
                                            }}
                                        >
                                            Убрать закупку
                                        </Button>
                                    </Stack>
                                </CardContent>
                            </Card>
                        )
                    }

                    {/*Pagination*/}
                    {
                        this.props.pageCount > 0 &&
                        <Box sx={{mt: 2}} className={styles.paginationContainer}>
                            <Stack spacing={2}>
                                <Pagination page={this.props.pageNumber} count={this.props.pageCount}
                                            onChange={(e, pageNumber) => this.props.onGetPurchases(this.props.selectedTagId, this.props.pageSize, pageNumber)}/>
                            </Stack>
                        </Box>
                    }
                </Box>

            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        tags: state.tagsState.tags,
        purchases: state.tagsState.purchases,
        selectedTagId: state.tagsState.selectedTagId,
        selectedTag: state.tagsState.selectedTag,
        pageSize: state.tagsState.pageSize,
        pageNumber: state.tagsState.pageNumber,
        pageCount: state.tagsState.pageCount,
        editTagDialogOpened: state.tagsState.editTagDialogOpened,
        editTagDialogErrors: state.tagsState.editTagDialogErrors,
        editTag: state.tagsState.editTag,
        editTagId: state.tagsState.editTagId,
        isTagPurchasesLoading: state.tagsState.isTagPurchasesLoading,
        isTagsLoading: state.tagsState.isTagsLoading,
        selectedPurchase: state.selectTagDialogState.selectedPurchase,
        tagDialogSelectedPurchase: state.selectTagDialogState.selectedPurchase
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onOpenEditTagDialog: (tagId) => {
            if (tagId) {
                PurchaseTagsService.getTag(tagId)
                    .then((tag) => {
                        const action = {
                            type: 'EDIT_DIALOG_OPEN',
                            editTag: tag,
                            editTagId: tagId
                        };
                        dispatch(action);
                    })
                    .catch((error) => {
                        const notificationAction = {
                            type: 'SNACKBAR_NOTIFICATION_SENT',
                            notification: {
                                message: 'Не удалось получить данные по папке избранного',
                                variant: 'error'
                            }
                        };
                        dispatch(notificationAction);
                    });
            } else {
                const action = {
                    type: 'EDIT_DIALOG_OPEN',
                    editTag: {},
                    editTagId: null
                };
                dispatch(action);
            }
        },
        onSaveEditDialog: (tag, tagId) => {
            if (tagId) {
                PurchaseTagsService.updateTag(tagId, tag)
                    .then((response) => {
                        if (response && response.status >= 400 && response.errors) {
                            const action = {
                                type: 'EDIT_DIALOG_ERROR',
                                errors: response.errors['Name']
                            };
                            dispatch(action);
                        } else {
                            const notificationAction = {
                                type: 'SNACKBAR_NOTIFICATION_SENT',
                                notification: {
                                    message: 'Папка избранного успешно сохранена',
                                    variant: 'success'
                                }
                            };
                            dispatch(notificationAction);

                            const action = {
                                type: 'EDIT_DIALOG_SAVE',
                                tag: tag,
                                tagId: tagId
                            };
                            dispatch(action);
                        }
                    })
                    .catch((error) => {
                        const notificationAction = {
                            type: 'SNACKBAR_NOTIFICATION_SENT',
                            notification: {
                                message: 'Произошла ошибка при редактировании папки избранного',
                                variant: 'error'
                            }
                        };
                        dispatch(notificationAction);
                    });
            } else {
                PurchaseTagsService.addTag(tag)
                    .then((response) => {
                        if (response && response.status >= 400 && response.errors) {
                            const action = {
                                type: 'EDIT_DIALOG_ERROR',
                                errors: response.errors['Name']
                            };
                            dispatch(action);
                        } else {
                            const notificationAction = {
                                type: 'SNACKBAR_NOTIFICATION_SENT',
                                notification: {
                                    message: 'Папка избранного успешно сохранена',
                                    variant: 'success'
                                }
                            };
                            dispatch(notificationAction);

                            const action = {
                                type: 'ADD_DIALOG_SAVE',
                                tag: {id: response, name: tag.name}
                            };
                            dispatch(action);
                        }
                    })
                    .catch((error) => {
                        const notificationAction = {
                            type: 'SNACKBAR_NOTIFICATION_SENT',
                            notification: {
                                message: 'Произошла ошибка при добавлении папки избранного',
                                variant: 'error'
                            }
                        };
                        dispatch(notificationAction);
                    });
            }
        },
        onCancelEditDialog: () => {
            const action = {
                type: 'EDIT_DIALOG_CANCEL'
            };
            dispatch(action);
        },
        onEditTagName: (value) => {
            const action = {
                type: 'TAG_NAME_EDIT',
                tagName: value
            };
            dispatch(action);
        },
        onUpdateTags: () => {
            const action = {
                type: 'GET_TAGS_STARTED'
            };
            dispatch(action);

            PurchaseTagsService.getTags()
                .then(result => {
                    const action = {
                        type: 'GET_TAGS_FINISHED',
                        tags: result
                    };
                    dispatch(action);
                })
                .catch((error) => {
                    const action = {
                        type: 'GET_TAGS_ERROR'
                    };
                    dispatch(action);

                    const notificationAction = {
                        type: 'SNACKBAR_NOTIFICATION_SENT',
                        notification: {
                            message: 'Не удалось получить папки избранного',
                            variant: 'error'
                        }
                    };
                    dispatch(notificationAction);
                })
        },
        onGetPurchases: (tagId, pageSize, pageNumber) => {
            const action = {
                type: 'TAG_SELECTED'
            };
            dispatch(action);

            PurchaseTagsService.getPurchasesForTag(tagId, pageSize, pageNumber)
                .then(result => {
                    if (result) {
                        //if result have purchases and page have not - render previous page
                        if (result.total > 0 && result.collections.length === 0) {
                            PurchaseTagsService.getPurchasesForTag(tagId, pageSize, pageNumber - 1)
                                .then((result) => {
                                    PurchaseService.getMany(result.collections)
                                        .then(purchases => {
                                            const action = {
                                                type: 'PURCHASES_FOR_TAG_FOUND',
                                                purchases: purchases,
                                                selectedTagId: tagId,
                                                pageCount: result.pageCount,
                                                pageNumber: pageNumber
                                            };
                                            dispatch(action);
                                        });
                                })
                            return;
                        }
                        PurchaseService.getMany(result.collections)
                            .then(purchases => {
                                const action = {
                                    type: 'PURCHASES_FOR_TAG_FOUND',
                                    purchases: purchases,
                                    selectedTagId: tagId,
                                    pageCount: result.pageCount,
                                    pageNumber: pageNumber
                                };
                                dispatch(action);
                            });
                    } else {
                        const action = {
                            type: 'PURCHASES_FOR_TAG_FOUND',
                            purchases: [],
                            total: 0,
                            pageNumber: 1,
                            selectedTagId: tagId
                        };
                        dispatch(action);
                    }
                })
                .catch(error => {
                    const notificationAction = {
                        type: 'SNACKBAR_NOTIFICATION_SENT',
                        notification: {
                            message: 'Произошла ошибка при получении закупок в папке избранного',
                            variant: 'error'
                        }
                    };
                    dispatch(notificationAction);

                    const action = {
                        type: 'ERROR_WHILE_GET_PURCHASES_FOR_TAG'
                    };
                    dispatch(action);
                });
        },
        onRemovingPurchaseTag: async (purchaseTag) => {
            return PurchaseTagsService.removePurchaseTag(purchaseTag)
                .then(result => {
                    const action = {
                        type: 'PURCHASE_TAG_REMOVED',
                        purchaseId: purchaseTag.purchaseId
                    };
                    dispatch(action);
                })
                .catch((error) => {
                    const notificationAction = {
                        type: 'SNACKBAR_NOTIFICATION_SENT',
                        notification: {
                            message: 'Ошибка при удалении закупки из избранного',
                            variant: 'error'
                        }
                    };
                    dispatch(notificationAction);
                });
        },
        onRemovingTag: (tagId) => {
            PurchaseTagsService.removeTag(tagId)
                .then(result => {
                    const notificationAction = {
                        type: 'SNACKBAR_NOTIFICATION_SENT',
                        notification: {
                            message: 'Папка избранного успешно удалена',
                            variant: 'success'
                        }
                    };
                    dispatch(notificationAction);

                    const action = {
                        type: 'TAG_REMOVED',
                        tagId: tagId
                    };
                    dispatch(action);
                })
                .catch((error) => {
                    const notificationAction = {
                        type: 'SNACKBAR_NOTIFICATION_SENT',
                        notification: {
                            message: 'Ошибка при удалении папки избранного',
                            variant: 'error'
                        }
                    };
                    dispatch(notificationAction);
                });
        },
        onPurchaseTagChangeCalled: (purchase, tagId) => {
            const purchaseIds = [purchase.id];
            PurchaseTagsService.getPurchaseTags(purchaseIds)
                .then((result) => {
                    purchase.tags = result[0].tags;
                    PurchaseTagsService.getTags()
                        .then(result => {
                            const action = {
                                type: 'OPEN_TAG_DIALOG',
                                selectedPurchase: purchase,
                                //selectedTagId: tagId,
                                tags: result
                            };
                            dispatch(action);
                        })
                        .catch((error) => {
                            const notificationAction = {
                                type: 'SNACKBAR_NOTIFICATION_SENT',
                                notification: {
                                    message: 'Не удалось получить папки избранного',
                                    variant: 'error'
                                }
                            };
                            dispatch(notificationAction);
                        })
                })
                .catch((error) => {
                    const notificationAction = {
                        type: 'SNACKBAR_NOTIFICATION_SENT',
                        notification: {
                            message: 'Не удалось получить папки избранного',
                            variant: 'error'
                        }
                    };
                    dispatch(notificationAction);
                });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tags);
