import React from 'react'
import { Typography, Box, Button, Container, TextField, FormGroup } from '@mui/material'

import { Formik, Field, Form } from 'formik';
import * as Yup from "yup";
import { useSnackbar } from "notistack";

export const PasswordReset = () => {
    const { enqueueSnackbar } = useSnackbar();

    const handleSendEmail = async (email) => {
        const requestOptions = {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*', 
            }
        };

        const baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558';	
        const response = fetch(`${baseIdentityURL}/api/account/reset-password?email=${email}`, requestOptions)
        response
            .then(() => {
                enqueueSnackbar(`на указанный e-mail отправлено письмо с инструкцией по восстановлению пароля`, { variant: "success" });
            })
            .catch(() => {
                enqueueSnackbar(`Произошла ошибка при отправке e-mail`, { variant: "error" });
            });
    }

    const validationSchema = Yup.object({
        email: Yup.string()
            .email('Должен быть указан корректный e-mail')
            .required('Обязательное поле')
    });

    return (
        <React.Fragment>
            <Container maxWidth="xs">
				<Box
					sx={{
						marginTop: 8,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
                    <Typography component="h1" variant="h5">
                        Восстановление пароля
                    </Typography>
                    <Formik 
                        initialValues={{email: ''}}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            handleSendEmail(values.email);
                            setSubmitting(false);
                        }}
                    >
                    { formik => (
                        <Form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
                            <Field name="email">
                                {({field, form, meta}) => (
                                    <FormGroup>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            id="email"
                                            label="E-mail"
                                            name="email"
                                            helperText={meta.touched && meta.error}
                                            error={ meta.touched && meta.error != null }
                                            {...formik.getFieldProps('email')}
                                        />
                                    </FormGroup>
                                )}
                            </Field>
                            <Button
                                type="submit"
                                disabled={formik.isSubmitting}
                                fullWidth
                                variant="contained"
                                sx={{ mt: 1, mb: 2 }}
                            >
                                Восстановить
                            </Button>
                        </Form>
                    )}
                    </Formik>
			    </Box>
            </Container>
		</React.Fragment>
    )
}
