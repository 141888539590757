const initialState = {
  organizationInn: '',
  organization: null,
  currentPage: 'ORGANIZATIONS'
}

const reducer = (state = initialState, action) => {
  if (action.type === 'SEARCH_ORGANIZATION') {
    return {
      ...state,
      isLoading: true
    }
  }

  if (action.type === 'ORGANIZATION_SEARCH_COMPLETED') {
    return {
      ...state,
      organization: action.organization,
      isLoading: false,
    }
  }

  return state;
}

export default reducer;