import React, { Component } from 'react';
import styles from '../css/Menu/Menu.module.css'
import { connect } from 'react-redux';
import { withRouter } from '../withRouter';
import {Link, NavLink} from 'react-router-dom';
import {isMobile} from "react-device-detect";
import AuthHeader from "./Authentication/AuthHeader";

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false
    }
  }

  transportPurchaseLogsPage = (props) => {
    var isShow = false;

    if (Array.isArray(props.permissions)) {
      if (props.permissions.some(permission => permission.indexOf('admin.unpub') > -1)) {
        isShow = true;
      }
    } else if (typeof props.permissions === 'string') {
      if (props.permissions.indexOf('admin.unpub') > -1) {
        isShow = true;
      }
    }

    if (isShow) {
      return <Link to="/purchases/logs" style={{textDecoration: 'none'}} onClick={() => this.props.onPurchaseTransportLogsPage()}>Перемещение закупок</Link>;
    }
  }

  render() {
    return (
      <div className={styles.menu}>
        <NavLink to="/purchases" style={{textDecoration: 'none'}} onClick={() => this.props.onPurchasePage()} end>
          <img src="/search_icon_menu.svg" alt="search_icon_menu" className={styles.menuIcon}/>
          Закупки
        </NavLink>
        <NavLink to="/purchases/templates" style={{textDecoration: 'none'}} onClick={() => this.props.onTemplatePage()}>
          <img src="/templates_icon.svg" alt="templates_icon_menu" className={styles.menuIcon}/>
          Шаблоны
        </NavLink>
        <NavLink to="/tags" style={{textDecoration: 'none'}} onClick={() => this.props.onTagsPage()}>
          <img src="/star_icon_menu.svg" alt="star_icon_menu" className={styles.menuIcon}/>
          Избранное
        </NavLink>
        <NavLink to="/organizations" style={{textDecoration: 'none'}} onClick={() => this.props.onOrganizationsPage()}>
          <img src="/companies_icon_menu.svg" alt="companies_icon_menu" className={styles.menuIcon}/>
          Компании
        </NavLink>
        {
            isMobile && <div style={{textDecoration: 'none'}} className={styles.menuAdditionalButton} onClick={() => {
              this.setState({
                active: !this.state.active
              })
            }}>
              <img src="/other_icon_menu.svg" alt="other_icon_menu" className={styles.menuIcon}/>
              Еще
            </div>
        }
        {isMobile && <div className={this.state.active ? styles.authHeaderContainer + ' ' + styles.activeMenu : styles.authHeaderContainer}>
          <AuthHeader/>
        </div>}
        {this.transportPurchaseLogsPage(this.props)}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentPage: state.pagesState.currentPage,
    permissions: state.profileState.permissions
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onPurchasePage: () => {
      const action = {
        type: 'PURCHASE_PAGE'
      };
      dispatch(action);
    },
    onTemplatePage: () => {
      const action = {
        type: 'TEMPLATE_PAGE'
	  };
      dispatch(action);
    },
    onTagsPage: () => {
      const action = {
        type: 'TAGS_PAGE'
      };
      dispatch(action);
    },
    onOrganizationsPage: () => {
      const action = {
        type: 'ORGANIZATION_PAGE'
      };
      dispatch(action);
    },
    onPurchaseTransportLogsPage: () => {
      const action = {
        type: 'PURCHASE_LOG_PAGE'
      };
      dispatch(action);      
    }
  }
}  
  
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Menu));
