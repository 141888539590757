import React from "react"
import {Button, Card, Grid, Link, Rating, Typography} from "@mui/material"
import htmlParse from 'html-react-parser';
import {PurchaseStageAndDates} from "./purchase-stage-and-dates";
import {useDispatch, useSelector} from "react-redux";

export const PurchaseCard = (props) => {
    const purchase = props.purchase;

    const reduxState = useSelector((state) => {
        return {
            currentPage: state.pagesState.currentPage
        };
    });
    const dispatch = useDispatch();

    const markPurchase = () => {
        const action = {
            type: 'ADD_UPLOAD_PURCHASE',
            uploadPurchaseId: purchase.id,
            currentPage: reduxState.currentPage
        }
        dispatch(action);
    }

    const unmarkPurchase = () => {
        const action = {
            type: 'REMOVE_UPLOAD_PURCHASE',
            uploadPurchaseId: props.purchase.id,
            currentPage: reduxState.currentPage
        }
        dispatch(action);
    }

    const getLaw = (purchase) => {
        switch (purchase.law) {
            case 1:
                return '44-ФЗ';
            case 2:
                return '223-ФЗ';
            case 3:
                return 'Коммерческие';
            case 4:
                return 'ПП РФ 615';
            default:
                return 'Не определено';
        }
    }

    return (
        <React.Fragment>
            <Card sx={{mb: 1, ml: 1, mr: 1, boxShadow: '-3px 3px 16px #0000000F', p: 2}}>
                <Grid container>
                    {purchase.recommended === true ?
                        <Grid item>
                            <Typography variant="overline" color="red">Рекомендовано Эксперт-Центром!</Typography>
                        </Grid>
                        : null
                    }
                </Grid>
                <Grid container sx={{mb: 1}}>
                    <Grid item>
                        <Link target="_blank" href={"/purchases/" + purchase.id} sx={{textDecoration: "none"}}>
                            <Typography className="highlighted"
                                        sx={{
                                            fontSize: "16px!important",
                                            fontWeight: "500",
                                            fontFamily: "SF Pro Text, sans-serif!important",
                                            letterSpacing: "-0.41px!important",
                                            color: "#000000",
                                            overflow: "hidden",
                                            display: "-webkit-box",
                                            WebkitLineClamp: "3",
                                            WebkitBoxOrient: "vertical"
                                        }}>
                                {htmlParse(purchase.title)}
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Typography variant="caption" color="text.secondary" component="div" sx={{
                            paddingTop: "4px",
                            fontSize: "12px!important",
                            fontWeight: "400",
                            fontFamily: "SF Pro Text, sans-serif!important",
                            letterSpacing: "-0.21px!important"
                        }}>
                            {[getLaw(purchase), purchase.methodType, purchase.number].filter(Boolean).join(', ')}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container sx={{mb: 1}}>
                    <Grid item>
                        <Typography sx={{
                            fontSize: "18px!important",
                            fontWeight: "500",
                            fontFamily: "SF Pro Text, sans-serif!important",
                            letterSpacing: "-0.41px!important"
                        }}>
                            {purchase.price ? `${purchase.price} ₽` : '–'}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container sx={{mb: 1}}>
                    <Grid item>
                        <PurchaseStageAndDates purchase={purchase}/>
                    </Grid>
                </Grid>
                <Grid container sx={{mb: 1}}>
                    <Grid>
                        <Typography variant="caption"
                                    sx={{
                                        pt: 2,
                                        fontSize: "14px!important",
                                        lineHeight: "1.4",
                                        fontWeight: "400",
                                        fontFamily: "SF Pro Text, sans-serif!important",
                                        letterSpacing: "-0.41px!important",
                                        overflow: "hidden",
                                        display: "-webkit-box",
                                        WebkitLineClamp: "3",
                                        WebkitBoxOrient: "vertical"
                                    }} component="div">{purchase.customerOrganizationName}</Typography>
                        <Rating value={parseFloat(purchase.customerRating)} precision={0.1} readOnly/>
                    </Grid>
                </Grid>
                <Grid container sx={{mb: 1}}>
                    {props.isMarked ? (
                            <Button
                                sx={{
                                    padding: '8px 16px'
                                }}
                                variant="contained"
                                onClick={() => unmarkPurchase()}
                            >
                                Отмечено
                            </Button>
                        )
                        : (
                            <Button
                                sx={{
                                    padding: '8px 16px!important',
                                    width: "120px!important"
                                }}
                                variant="text"
                                onClick={() => markPurchase()}
                            >
                                Отметить
                            </Button>
                        )
                    }
                </Grid>
            </Card>
        </React.Fragment>
    )
}
