import React from "react"
import { MapContainer, TileLayer, useMapEvents } from 'react-leaflet';
import { MapEvents } from "./map-events";
import L from 'leaflet';

import "./assets/leaflet.css";

import { ClickableMarker } from "./clickable-marker";

const style = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
}

export const Map = (props) => {

    return (
        <React.Fragment> 
            <MapContainer 
                center={[64.107663, 103.59419]} 
                zoom={4}
                minZoom={4} 
                zoomControl={false}
                scrollWheelZoom={true} 
                style={style} 
                maxBounds={L.latLngBounds(L.latLng(80, 0), L.latLng(40, 179))} 
            >
                <MapEvents onMapSearch={props.onMapSearch}/>
                <TileLayer
                    attribution='<a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {
                    props.purchaseClusters.map((purchaseCluster, key) => {
                        return (
                            <ClickableMarker purchaseCluster={purchaseCluster} key={key} />
                        )
                    })
                }
            </MapContainer>  
		</React.Fragment>
    )
}
