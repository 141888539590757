import { SearchResultsTabId, CheckedTabId } from "../../components/CustomerSelection";

const initialState = {
  foundOrganizations: [],
  foundOrganizationsTotalCount: 0,
  checkedOrganizations: [],
  openModal: false,
  activeTabId: SearchResultsTabId,
  isLoading: false
}

const reducer = (state = initialState, action) => {
  if (action.type === 'CUSTOMER_SELECTION_OPENED') {
    return {
      ...state,
      foundOrganizations: initialState.foundOrganizations,
      foundOrganizationsTotalCount: initialState.foundOrganizationsTotalCount,
      checkedOrganizations: action.checkedOrganizations ? action.checkedOrganizations : state.checkedOrganizations,
      openModal: true,
      activeTabId: CheckedTabId
    }
  }

  if (action.type === 'CUSTOMER_SELECTION_CLOSED') {
    return {
      ...state,
      openModal: false
    }
  }

  if (action.type === 'CUSTOMER_SELECTION_COMPLETED') {
    return {
      ...state,
      openModal: false
    }
  }

  if (action.type === 'CUSTOMER_SELECTION_SEARCH_STARTED') {
    return {
      ...state,
      activeTabId: SearchResultsTabId,
      isLoading: true
    }
  }

  if (action.type === 'CUSTOMER_SELECTION_SEARCH_FAILED') {
    return {
      ...state,
      isLoading: false
    }
  }

  if (action.type === 'CUSTOMER_SELECTION_FOUND') {
    return {
      ...state,
      foundOrganizations: action.foundOrganizations,
      activeTabId: SearchResultsTabId,
      foundOrganizationsTotalCount: action.foundOrganizationsTotalCount,
      isLoading: false
    }
  }

  if (action.type === 'CUSTOMER_SELECTION_ADDED') {
    const newFoundOrganizations = [...state.foundOrganizations, ...action.addedOrganizations]
    console.log(newFoundOrganizations);
    return {
      ...state,
      foundOrganizations: newFoundOrganizations
    }
  }

  if (action.type === 'CUSTOMER_SELECTION_CHECKBOX_CHANGED_SINGLE') {
    if (action.checked === true) {
      if (!state.checkedOrganizations.some((org) => org.id === action.selectedOrganization.id)) {
        const newCheckedOrganizations = [...state.checkedOrganizations, action.selectedOrganization];
        return {
          ...state,
          checkedOrganizations: newCheckedOrganizations
        };
      }
    }
    else {
      const newCheckedOrganizations = state.checkedOrganizations.filter((org) => org.id !== action.selectedOrganization.id);
      return {
        ...state,
        checkedOrganizations: newCheckedOrganizations
      };
    }   
  }

  if (action.type === 'CUSTOMER_SELECTION_CHECKBOX_CHANGED_ALL') {
    if (state.activeTabId === SearchResultsTabId) {
      if (action.checked === true) {
        return {
          ...state,
          checkedOrganizations: [...state.foundOrganizations]
        }
      }
      else {
        const newCheckedOrganizations = state.checkedOrganizations.filter((checkedOrg) => !state.foundOrganizations.some((foundOrg) => foundOrg.id === checkedOrg.id));
        return {
          ...state,
          checkedOrganizations: newCheckedOrganizations
        }
      }       
    }

    if (state.activeTabId === CheckedTabId) {
      return {
        ...state,
        checkedOrganizations: initialState.checkedOrganizations
      }
    }
  }

  if (action.type === 'CUSTOMER_SELECTION_TAB_CHANGED') {
    return {
      ...state,
      activeTabId: action.tabId
    }
  }

  return state;
}

export default reducer;