import { Log, UserManager, WebStorageStateStore } from 'oidc-client';

var baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558';
const settings = {
  authority: baseIdentityURL,
  client_id: 'web_client',
  redirect_uri: window.location.origin + '/signin-callback',
  automaticSilentRenew: true,
  revokeAccessTokenOnSignout: true,
  silent_redirect_uri: window.location.origin + '/silent-renew-callback',
  post_logout_redirect_uri: window.location.origin + '/logout-callback',
  response_type: 'id_token token',
  //TODO delete clientportal when testing ends
  scope: 'purchases templates openid profile administration',
  userStore: new WebStorageStateStore({ store: localStorage}),
  monitorSession: false,
};
const userManager = new UserManager(settings);

userManager.events.addSilentRenewError((error) => {
  console.log("Не удается получить токен " + error);
  userManager.signoutRedirect();
});

Log.logger = console;
Log.level = Log.INFO;

export default userManager;