export const StagesList = [
    {
        id: 1,
        name: "Подача заявок"
    },
    {
        id: 2,
        name: "Работа комиссии"
    },
    {
        id: 3,
        name: "Завершены"
    },
    {
        id: 4,
        name: "Отменены"
    },
    {
        id: 5,
        name: "Не состоялись"
    },
    {
        id: 6,
        name: "Планируются"
    },
    {
        id: 7,
        name: "На исполнении"
    }
]
