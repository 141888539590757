import React, {Component} from 'react';
import PurchaseReportService from '../services/PurchaseReportService';
import {Button, Grid} from '@mui/material';
import {withSnackbar} from 'notistack';
import {connect} from 'react-redux';
import {withRouter} from '../withRouter';

class MapMarkedPurchasesPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpenUploadSettingsModal: false,
            isLoadingUploadSettings: false
        };

        this.handleOpenUploadSettingsModal = this.handleOpenUploadSettingsModal.bind(this);
        this.handleConvertToString = this.handleConvertToString.bind(this);
        this.handleCloseUploadSettingsWithoutSaveModal = this.handleCloseUploadSettingsWithoutSaveModal.bind(this);
        this.handleUploadFieldChange = this.handleUploadFieldChange.bind(this);
    }

    handleUploadFieldChange(event) {
        var isChecked = event.target.checked;
        var value = event.target.value;

        if (isChecked) {
            this.props.onAddUploadField(value, this.props.currentPage);
        } else {
            this.props.onRemoveUploadField(value, this.props.currentPage);
        }
    }

    handleOpenUploadSettingsModal() {
        PurchaseReportService.getSettings()
            .then(settings => {
                this.props.onUploadSettings(settings, this.props.currentPage);
                this.setState({
                    isLoadingUploadSettings: false,
                    isOpenUploadSettingsModal: true
                });
            });

        this.setState({
            isLoadingUploadSettings: true
        })
    }

    handleConvertToString() {
        PurchaseReportService.convertToString(this.props.uploadPurchaseIds)
            .then(async (str) => {

                await navigator.clipboard.writeText(str);

                this.props.enqueueSnackbar(
                    'Подборки скопированы в буфер',
                    {
                        variant: 'success',
                        anchorOrigin: {vertical: 'top', horizontal: 'right'},
                        autoHideDuration: 2000
                    })
            });
    }

    handleCloseUploadSettingsWithoutSaveModal() {
        this.setState({
            isOpenUploadSettingsModal: false
        })
    }

    handleCloseUploadSettingsWithSaveModal() {
        this.setState({
            isOpenUploadSettingsModal: false
        })

        PurchaseReportService.addOrUpdateSettings(this.props.uploadFields);
    }

    render() {
        if (this.props.total > 0) {
            return (
                <React.Fragment>
                    <div>
                        <Grid container>
                            <Grid item xs={12}>
                                <Button
                                    variant="outlined"
                                    onClick={() => this.props.onClearUploadPurchases(this.props.currentPage)}
                                    style={{width: '100%'}}
                                >
                                    Очистить отмеченные ({this.props.uploadPurchaseIds.length})
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="outlined"
                                    style={{width: '100%'}}
                                    onClick={
                                        () =>
                                            this.handleConvertToString()
                                    }
                                >
                                    Копировать в буфер
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </React.Fragment>
            );
        }
    }
}

function mapStateToProps(state) {
    if (state.pagesState.currentPage === "PURCHASES") {
        return {
            uploadFields: state.markedPurchasesState.uploadFields,
            uploadPurchaseIds: state.markedPurchasesState.uploadPurchaseIds,
            total: state.markedPurchasesState.total,
            currentPage: state.pagesState.currentPage
        };
    }
    if (state.pagesState.currentPage === "TEMPLATES") {
        return {
            uploadFields: state.markedTemplatesState.uploadFields,
            uploadPurchaseIds: state.markedTemplatesState.uploadPurchaseIds,
            total: state.markedTemplatesState.total,
            currentPage: state.pagesState.currentPage
        };
    }
    return {
        purchases: [],
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onAddUploadField: (uploadField, currentPage) => {
            const action = {
                type: 'ADD_UPLOAD_FIELD',
                uploadField: uploadField,
                currentPage: currentPage
            }
            dispatch(action);
        },
        onRemoveUploadField: (uploadField, currentPage) => {
            const action = {
                type: 'REMOVE_UPLOAD_FIELD',
                uploadField: uploadField,
                currentPage: currentPage
            }
            dispatch(action);
        },
        onClearUploadPurchases: (currentPage) => {
            const action = {
                type: 'CLEAR_UPLOAD_PURCHASES',
                currentPage: currentPage
            }
            dispatch(action);
        }
    }
}

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(withRouter(MapMarkedPurchasesPanel)));
