import React from 'react';

//MUI
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Typography } from '@mui/material';

class CustomerReviewError extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Card variant="outlined" sx={{ minWidth: 275, mt: 1 }}>
          <CardContent>
            <Grid container spacing={3}>

              <Grid item xs={12}>
                <Typography variant="h5" sx={{ pt: 3 }} color="text.secondary" component="div" align='center'>
                  {this.props.text}
                </Typography>
              </Grid>

            </Grid>
          </CardContent>
        </Card>
      </React.Fragment>
    )
  }
}

export default CustomerReviewError;
