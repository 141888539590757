import { useMapEvents } from 'react-leaflet';

export const MapEvents = (props) => {
    const mapEvents = useMapEvents({
        zoomend: () => {
            const bounds = mapEvents.getBounds();
            const zoom = mapEvents.getZoom();
            props.onMapSearch(bounds._northEast.lat, bounds._northEast.lng, bounds._southWest.lat, bounds._southWest.lng, zoom);
        },
        moveend: () => {
            const bounds = mapEvents.getBounds();
            const zoom = mapEvents.getZoom();
            props.onMapSearch(bounds._northEast.lat, bounds._northEast.lng, bounds._southWest.lat, bounds._southWest.lng, zoom);
        }
    });

    return null;
}
