import React, { Component } from 'react';

import { Formik, Field, Form } from 'formik';
import * as Yup from "yup";

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText'

export class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = { 
		returnUrl: this.getReturnUrlFromQuery(),
		errors: []
	};

	this.flushErrors = this.flushErrors.bind(this);
	this.pushError = this.pushError.bind(this);
	this.handleSubmit = this.handleSubmit.bind(this);
  }
  
  getReturnUrlFromQuery() {
	  const query = new URLSearchParams(window.location.search);
	  var returnUrl = query.get('ReturnUrl');
	  return returnUrl;
  }
  
  flushErrors() {
	  this.setState({errors: []});
  }
	  
  pushError(error) {
	  this.setState({errors: [...this.state.errors, error] });
  }
  
  handleSubmit(values) {
	this.flushErrors();
	const requestOptions = {
                method: 'POST',
				headers: { 
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*', 
				},
				credentials: 'include',
                body: JSON.stringify({
					'username': values.email,
					'password': values.password,
					'confirmPassword': values.confirmPassword,
					'returnUrl': this.state.returnUrl
                })
            };
	const baseIdentityURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_IDENTITY_URI : 'http://localhost:5558';
	fetch(baseIdentityURL + "/api/account/signup/", requestOptions)
				.then(res => res.json())
                .then((response) => {
					if (response.succeeded) {
						alert('Пользователь успешно зарегистрирован!');
						window.location.replace(this.state.returnUrl);
					}
					else {
						response.errors.forEach((error)=>{this.pushError(error.description)});
					}
                },
                (error) => {
					this.pushError('Произошла ошибка при авторизации');
                })
  }

  validationSchema = Yup.object({
		email: Yup.string()
			.email('Должен быть указан корректный e-mail')
			.required('Обязательное поле'),
		password: Yup.string()
			.min(6, "Пароль должен быть не менее 6 символов")
			.required('Обязательное поле'),
		confirmPassword: Yup.string()
			.min(6, "Пароль должен быть не менее 6 символов")
			.oneOf([Yup.ref('password')], 'Введенные пароли не совпадают')
			.required('Обязательное поле')
	});

  render() {
	const errorDivs = this.state.errors.map((err) => <Grid item xs={12} sx={{color:'red'}}>{err}</Grid>);
	return (
		<React.Fragment>
			<Container maxWidth="xs">
					<Box
						sx={{
							marginTop: 8,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
					<Typography component="h1" variant="h5">
						Регистрация
					</Typography>
					<Formik 
                        initialValues={{email: '', password: '', confirmPassword: ''}}
                        validationSchema={this.validationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            this.handleSubmit(values);
                            setSubmitting(false);
                        }}
                    >
                    { formik => (
                        <Form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
                            <Field name="email" >
                                {({field, form, meta}) => (
                                    <FormGroup>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            id="email"
                                            label="E-mail"
                                            name="email"
                                            {...formik.getFieldProps('email')}
                                            helperText={meta.touched && meta.error}
                                            error={meta.touched  && meta.error != null}
                                        />
                                    </FormGroup>
                                )}
                            </Field>

                            <Field name="password" >
                                {({field, form, meta}) => (
                                    <FormGroup>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            type="password"
                                            id="password"
                                            label="Пароль"
                                            name="password"
                                            {...formik.getFieldProps('password')}
                                            error={meta.touched  && meta.error != null}
                                            helperText={meta.touched && meta.error}
                                        />
                                    </FormGroup>
                                )}
                            </Field>

                            <Field name="confirmPassword" >
                                {({field, form, meta}) => (
                                    <FormGroup>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            type="password"
                                            id="confirmPassword"
                                            label="Подтверждение пароля"
                                            name="confirmPassword"
                                            {...formik.getFieldProps('confirmPassword')}
                                            error={meta.touched  && meta.error != null}
                                            helperText={meta.touched && meta.error}
                                        />
                                    </FormGroup>
                                )}
                            </Field>

                            {/* server errors */}
                            { this.state.errors.length > 0 
                                ? 
                                    this.state.errors.map((error, key) => 
                                        <FormHelperText error key={key}>{error}</FormHelperText>
                                    )   
                                : null
                            }
                            <Button
                                type="submit"
                                disabled={formik.isSubmitting}
                                fullWidth
                                variant="contained"
                                sx={{ mt: 1, mb: 2 }}
                            >
                                Зарегистрироваться
                            </Button>
                            
                        </Form>
                    )}
                    </Formik>
				</Box>
			</Container>
		</React.Fragment>
  )}
}
export default SignUp;