import { createRef } from 'react'
import DocumentsService from '../services/DocumentsService';
import { Link } from '@mui/material';
import { useSnackbar } from 'notistack';

export function DownloadDocumentLink ({ path, filename, children }) {
  const { enqueueSnackbar } = useSnackbar();

  const linkStyle = {
      cursor: 'pointer'
  };

  const link = createRef();

  const handleAction = async () => {
    if (link.current.href) { return }
  
    const result = await DocumentsService.get(path, filename)
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(`Не удалось скачать файл`, { variant: "error" });
      });
    
    if (result)
    {
        const blob = new Blob([result]);
        const href = window.URL.createObjectURL(blob);
        
        link.current.download = filename;
        link.current.href = href;
        link.current.style.cursor = 'pointer';
        
        link.current.click();
    }
  }

  return (
    <>
      <Link ref={link} onClick={handleAction} sx={{ mr:0.5, ml:0.5 }} style={linkStyle} color="inherit" underline="none" variant="inherit">{children}</Link>
    </>
  )
}